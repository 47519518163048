import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface WebhookDetailModalProps {
  show: boolean;
  onHide: () => void;
  webhook: any;
}

const WebhookDetailModal: React.FC<WebhookDetailModalProps> = ({
  show,
  onHide,
  webhook,
}) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("configs.webhook.modal.details")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre>{JSON.stringify(webhook, null, 2)}</pre>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t("configs.webhook.modal.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WebhookDetailModal;
