import { useQuery, UseQueryResult, QueryKey } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from ".";
import { APiQueryBase, ApiResponseBase } from "../interfaces/ApiResponseBase";
import { ErrorResult } from "../interfaces/ErrorResult";

type ReportsResponse = ApiResponseBase<any[]>;

export class ReportsService {
  static async get({
    filter,
    page,
    order,
  }: APiQueryBase): Promise<ReportsResponse | ErrorResult> {
    try {
      const response = await api.get<ReportsResponse>("/api/v2/reports", {
        params: {
          page,
          per_page: 10,
          filter: JSON.stringify(filter),
          order: JSON.stringify(order),
        },
      });
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResult>;
      return {
        success: false,
        message: axiosError.response?.data?.message || "Unknown error occurred",
      } as ErrorResult;
    }
  }
  static async generateSensorsExcelReport({
    filter,
  }: APiQueryBase): Promise<ReportsResponse | ErrorResult> {
    try {
      const response = await api.get<ReportsResponse>(
        "/api/v2/sensors/report/excel",
        {
          params: {
            filter: JSON.stringify(filter),
          },
        }
      );
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResult>;
      return {
        success: false,
        message: axiosError.response?.data?.message || "Unknown error occurred",
      } as ErrorResult;
    }
  }
}

export const useReports = (
  query: APiQueryBase,
  options?: {
    enabled?: boolean;
    refetchInterval?: number | false;
    refetchIntervalInBackground?: boolean;
    refetchOnWindowFocus?: boolean;
    staleTime?: number;
    cacheTime?: number;
    retry?: boolean | number;
    retryDelay?: number | ((attemptIndex: number) => number);
    onSuccess?: (data: ReportsResponse | ErrorResult) => void;
    onError?: (error: AxiosError<ErrorResult>) => void;
    select?: (data: ReportsResponse | ErrorResult) => any;
    [key: string]: any;
  }
): UseQueryResult<ReportsResponse | ErrorResult, AxiosError<ErrorResult>> => {
  return useQuery<ReportsResponse | ErrorResult, AxiosError<ErrorResult>>(
    ["reports", query],
    () => ReportsService.get(query),
    {
      ...options, // Spread das opções fornecidas
    }
  );
};
