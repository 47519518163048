import { api } from ".";

export class FileService {
  static async fetchFile(path: string) {
    return await api
      .post("/api/v2/file/get-blob", { path }, { responseType: "blob" })
      .then((r) => r)
      .catch((e) => e?.Response?.data);
  }
}
export const fetchPDFFile = async (path: string): Promise<Blob | null> => {
  try {
    const response = await api.post(
      "/api/v2/file/get-blob-img-to-pdf",
      { path },
      {
        responseType: "blob",
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching PDF file:", error);
    return null;
  }
};
