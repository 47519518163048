// Teste build
import React from "react";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./App.css";
import i18n from "./config/i18n";
import RoutesRoot from "./routes";
import { store } from "./core/redux/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

function App() {
  const queryClient = new QueryClient();
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
          <I18nextProvider key="default" i18n={i18n}>
            <ToastContainer />
            <RoutesRoot />
          </I18nextProvider>
        </DndProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
