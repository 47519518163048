import { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Stack, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaFilter } from "react-icons/fa";
import { DateTimerPicker } from "../DateTimerPicker";
import {
  parse,
  format,
  isValid,
  differenceInMonths,
  isAfter,
  subMonths,
  isDate,
} from "date-fns";
import { ReportsService } from "../../core/services/ReportsService";
import { toast } from "react-toastify";

interface SensorsReportsModalProps {
  visible: boolean;
  onClose?(): void;
  onChange?(filters: { startDate?: Date; endDate?: Date }): void;
}

const SensorsReportsModal: React.FC<SensorsReportsModalProps> = ({
  visible,
  onClose = () => {},
  onChange = () => {},
}) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDateChange = (value: string, isStart: boolean) => {
    const date = parse(value, "dd/MM/yyyy HH:mm", new Date());

    if (!isValid(date)) {
      setError(t("sensors_generatexls_modal.error.invalid_date"));
      return;
    }

    if (isStart) {
      if (
        endDate &&
        differenceInMonths(
          date,
          parse(endDate, "dd/MM/yyyy HH:mm", new Date())
        ) > 1
      ) {
        setError(t("sensors_generatexls_modal.error.date_range_exceeded"));
        return;
      }
      setStartDate(format(date, "dd/MM/yyyy HH:mm"));
      onChange({
        startDate: date,
        endDate: endDate
          ? parse(endDate, "dd/MM/yyyy HH:mm", new Date())
          : undefined,
      });
    } else {
      if (
        startDate &&
        differenceInMonths(
          parse(startDate, "dd/MM/yyyy HH:mm", new Date()),
          date
        ) > 1
      ) {
        setError(t("sensors_generatexls_modal.error.date_range_exceeded"));
        return;
      }
      if (
        startDate &&
        isAfter(parse(startDate, "dd/MM/yyyy HH:mm", new Date()), date)
      ) {
        setError(t("sensors_generatexls_modal.error.start_after_end"));
        return;
      }
      setEndDate(format(date, "dd/MM/yyyy HH:mm"));
      onChange({
        startDate: startDate
          ? parse(startDate, "dd/MM/yyyy HH:mm", new Date())
          : undefined,
        endDate: date,
      });
    }

    setError(null);
  };

  const handleCancel = () => {
    onClose();
  };

  const generateReport = async () => {
    setIsLoading(true);
    const convertDate = (dateStringOrDate: string | Date) => {
      let parsedDate: Date;

      if (typeof dateStringOrDate === "string") {
        // Tenta converter a string para uma data usando o formato esperado
        parsedDate = parse(dateStringOrDate, "dd/MM/yyyy HH:mm", new Date());

        if (!isValid(parsedDate)) {
          console.error("Invalid date string:", dateStringOrDate);
          throw new Error(
            "Failed to parse date string. Please ensure the format is correct."
          );
        }
      } else if (isDate(dateStringOrDate)) {
        // Se já for um objeto Date, verifica se é válido
        if (!isValid(dateStringOrDate)) {
          console.error("Invalid Date object:", dateStringOrDate);
          throw new Error("Invalid Date object.");
        }
        parsedDate = dateStringOrDate;
      } else {
        throw new Error("Invalid input: Must be a string or a Date object.");
      }

      return format(parsedDate, "yyyy-MM-dd HH:mm:ss");
    };
    const startDateInput = startDate;
    const endDateInput = endDate;

    const _startDate = convertDate(startDateInput);
    const _endDate = convertDate(endDateInput);
    const filter = { start_date: _startDate, end_date: _endDate };

    try {
      await ReportsService.generateSensorsExcelReport({ filter });

      toast(
        <div>
          {t("sensors.toast.report_available.prefix")}{" "}
          <a
            href="/reports"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline", color: "blue" }}
          >
            {t("sensors.toast.report_available.link_text")}
          </a>
        </div>,
        {
          type: "success",
        }
      );

      onClose();
    } catch (err) {
      console.error(err);
      toast(<div>{t("sensors.toast.error_occurred")}</div>, { type: "error" });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const currentEndDate = format(new Date(), "dd/MM/yyyy HH:mm");
    const currentStartDate = format(
      subMonths(new Date(), 1),
      "dd/MM/yyyy HH:mm"
    );

    setStartDate(currentStartDate);
    setEndDate(currentEndDate);
    setIsLoading(false);
  }, []);
  return (
    <Modal show={visible} size="lg" centered onHide={handleCancel}>
      <Modal.Header className="border-0 gap-2" closeButton>
        <FaFilter size={18} />
        <Modal.Title>{t("sensors_generatexls_modal.filter")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Row>
          <Col xs={12} md={6}>
            <DateTimerPicker
              label={t("sensors_generatexls_modal.start_date")}
              value={startDate}
              onChange={(value) => handleDateChange(value, true)}
              onSelectDate={(date) =>
                date &&
                date?.date &&
                setStartDate(format(date.date, "dd/MM/yyyy HH:mm"))
              }
            />
          </Col>
          <Col xs={12} md={6}>
            <DateTimerPicker
              label={t("sensors_generatexls_modal.end_date")}
              value={endDate}
              onChange={(value) => handleDateChange(value, false)}
              onSelectDate={(date) =>
                date &&
                date?.date &&
                setEndDate(format(date.date, "dd/MM/yyyy HH:mm"))
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Stack
            className="d-flex justify-content-center"
            direction="horizontal"
            gap={3}
          >
            <Button
              onClick={generateReport}
              disabled={isLoading}
              aria-busy={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" />{" "}
                  {t("sensors_generatexls_modal.loading")}
                </>
              ) : (
                t("sensors_generatexls_modal.generate_excel")
              )}
            </Button>
          </Stack>
        </Row>
        {error && <p className="text-danger mt-3">{error}</p>}
      </Modal.Body>
    </Modal>
  );
};

export default SensorsReportsModal;
