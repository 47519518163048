export const pt = {
  password: "senha",
  search: "Buscar",
  searchJobDocument: "Nro. Documento",
  Carreta: "Carreta",
  "tela de janela": "Tela de janela",
  "porta do trailer": "Porta do trailer",
  "modo espera": "Status do Fleetmate",
  "inicio viagem": "Inicio viagem",
  "fim de viagem": "Fim de viagem",
  "Ao Entrar na Cerca Eletrô...": "Ao Entrar na Cerca Eletrô...",
  "Ao Sair da Cerca Eletrô...": "Ao Sair da Cerca Eletrô...",
  Carro: "Carro",
  Moto: "Moto",

  Toco: "Toco",
  Truck: "Truck",
  Utilitario: "Utilitário",
  Van: "Van",
  VUC: "VUC",
  export: "Exportar",
  Base: "Base",
  Cliente: "Cliente",
  no_equipment: "Sem Equipamento",
  "Área de risco": "Área de risco",
  "trava de baú lateral": "Trava de Baú Lateral",
  "trava da quinta roda": "Trava da Quinta Roda",
  "bloqueio de combustível": "Bloqueio de Combustível",
  "trava de baú principal": "Trava de Baú Principal",
  "quinta roda": "Quinta Roda",
  "porta do motorista": "Porta do Motorista",
  "porta do carona": "Porta do Carona",
  "trava do trailer": "Trava do trailer",
  painel: "Painel",
  engate: "Engate",
  "Ponto de parada autorizado": "Ponto de parada autorizado",
  Rota: "Rota",
  send: "Enviar",
  not_found: "Nada encontrado",

  FLUSH_DOOR_MAIN: "Porta Baú secundária",
  "aside.tracking": "Monitoramento",
  "aside.routing": "Roteirização",
  "aside.vehicles": "Veículos",
  "aside.drivers": "Motoristas",
  "aside.journey": "Jornada dos Motoristas",
  "aside.trips": "Viagens",
  "aside.sensors": "Sensores",
  "aside.fences": "Cercas Eletrônicas",
  "aside.alerts": "Alertas",
  "aside.reports": "Relatórios",
  "sidebar.change_company": "Alterar empresa",
  "sidebar.search": "Pesquisar...",
  "company.success": "Empresa alterada",
  "porta baú lateral": "Porta Baú Lateral",
  "porta baú principal": "Porta Baú Principal",
  "tracking.modal_move_confirm":
    "Você confirma a mudança da tarefa para o veículo",
  "tracking.modal_move_confirm_title":
    "Você confirma a mudança da tarefa para orfão?",
  sirene: "Sirene",
  ignição: "Ignição",
  silo: "Silo",
  "Quinta roda": "Quinta roda",
  pânico: "Pânico",
  "month.January": "Janeiro",
  "month.February": "Fevereiro",
  "month.March": "Março",
  "month.April": "Abril",
  "month.May": "Maio",
  "month.June": "Junho",
  "month.July": "Julho",
  "month.August": "Agosto",
  "month.September": "Setembro",
  "month.October": "Outubro",
  "month.November": "Novembro",
  "month.December": "Dezembro",
  "button.save": "Salvar",
  "button.delete": "Excluir",
  "button.cancel": "Cancelar",
  "button.confirm": "Confirmar",
  "text.yes": "Sim",
  "text.no": "Não",
  "confirm.message": "Confirmação",
  "uploadbox.sub_title_link": "Baixe um arquivo",
  "uploadbox.sub_title":
    "de exemplo para envio das tarefas em xls ou envie um arquivo em formato zip contendo os xml's das NFe's.",
  "uploadbox.success.message":
    "O processo de importação foi concluído! Verifique o retorno da importação no ambiente de alertas.",
  "uploadbox.message": "Em processamento",
  "text.edit": "Editar",
  "text.delete": "Apagar",
  "status.saving": "Salvando... ",
  "status.sending": "Enviando... ",
  "status.waiting": "Espere... ",
  "input.select_text": "Selecione",
  "placeholde.select": "Escolher veículo...",
  "link.go_back": "Voltar",
  "text.filter": "Filtrar",
  "text.map_view": "Visualizar no Mapa",
  "text.route_view": "Visualizar Rota",
  "text.required": "(Obrigatório)",
  "component.tag_label": "Atributo (TAG)",
  "message.confirm_delete_many_registers":
    "Você tem certeza que deseja apagar os registros selecionados?",
  "message.confirm_delete_register":
    "Você tem certeza que deseja excluir este registro?",
  selecteds: "Selecionados",

  "login.welcome_message": "Bem-Vindo de Volta!",
  "login.subtitle": "Insira seus dados para acessar o sistema",
  "login.password_label": "Senha",
  "login.email_placeholder": "Digite seu e-mail",
  "login.password_placeholder": "Digite sua senha",
  "login.remember_password": "Lembrar minha senha",
  "login.recover_password": "Recuperar a senha",

  "login.slide_first_title": "Temperatura e Umidade do baú",
  "login.slide_first_subtitle":
    "Controle a temperatura e umidade do baú utilizando data loggers Bluetooth com bateria interna de até 5 anos.",
  "login.slide_second_title": "Comportamento de direção",
  "login.slide_second_subtitle":
    "Evite acidentes e reduza os custos de operação da sua frota. Controle eventos de excesso de velocidade, uso do celular, frenagens, acelerações e curvas bruscas.",
  "login.slide_third_title": "Cercas Eletrônicas",
  "login.slide_third_subtitle":
    "Crie cercas circulares ou poligonais para controlar os tempos e movimentos da sua frota. Customize alertas e notificações e foque sua equipe somente nas exceções.",
  "login.slide_fourth_title": "Roteirização na Nuvem",
  "login.slide_fourth_subtitle":
    "Crie rotas otimizadas para sua frota considerando inúmeras restrições: tais como janela de atendimento, jornada dos motoristas, atributos do veículo, volume, peso e valor.",

  "forgot_password.back": "Voltar",
  "forgot_password.title": "Esqueceu a senha?",
  "forgot_password.subtitle":
    "Digite o endereço de e-mail que você usou quando ingressou e enviaremos instruções para redefinir sua senha.",
  "forgot_password.second_subtitle":
    "Por motivos de segurança, NÃO armazenamos sua senha. Portanto, tenha certeza de que nunca enviaremos sua senha por e-mail.",
  "forgot_password.email_label": "Endereço de e-mail",
  "forgot_password.placeholder": "Digite seu e-mail",
  "forgot_password.button_message": "Enviar instruções de definições",
  "forgot_password.success_message":
    "Uma mensagem foi enviada para o seu email.",

  "reset_password.title": "Redefinir senha",
  "reset_password.subtitle": "Crie uma nova senha",
  "reset_password.new_password": "Nova senha",
  "reset_password.new_password_placeholder": "Digite uma nova senha",
  "reset_password.repeat_password": "Nova senha (confirmação)",
  "reset_password.repeat_password_placeholder": "Repita a senha anterior",
  "reset_password.repeat_password_button": "Enviar instruções de definições",
  "reset_password.repeat_password_success_message":
    "Enviar Sua senha foi alterada com sucesso!",
  "reset_password.repeat_password_error_message": "Token inválido",
  "reset_password.back_to_login": "Voltar ao login",

  "drivers.add": "Adicionar Motoristas",
  "drivers.title": "Motoristas",
  "drivers.upload_title": "Arraste aqui para importar vários motoristas",
  "drivers.tasks_title": "Total de Tarefas Pendentes de Roteirização",
  "drivers.tasks_button": "Visualizar Tarefas",
  "drivers.table_title": "Motoristas Autorizados",
  "drivers.table_name": "NOME DO MOTORISTA",
  "drivers.table_document": "CPF",
  "drivers.table_email": "E-MAIL",
  "drivers.table_phone": "CELULAR",
  "drivers.table_authorized": "AUTORIZADO",
  "drivers.table_responsible": "RESPONSÁVEL",
  "drivers.delete_selected_registers": "Excluir Registros Selecionados",
  "drivers.delete_many_registers.message":
    "Você tem certeza que deseja excluir os motoristas selecionados?",
  "drivers.delete_registers.message":
    "Você tem certeza que deseja excluir este motorista?",
  "drivers.add_upload_label":
    "Adicione o e-mail, telefone ou CPF dos motoristas.",
  "drivers.add_upload_subtitle":
    "Os dados devem estar separados por vírgula ou ponto e vírgula.",
  "drivers.add_upload_enter": "Copie e Cole Aqui",
  "drivers.add_button": "Adicionar",
  "drivers.cancel_button": "Cancelar",
  "drivers.add_driver_text_confirm":
    "Por favor verifique os registros e confirme a adição dos motoristas",
  "drivers.add_register_found": "registros encontrados",
  "drivers.success_title": "motoristas adicionados com sucesso!",
  "drivers.add.message_error": "Falha ao adicionar motoristas",
  "drivers.delete_many_success_title": "motoristas excluidos com sucesso!",
  "drivers.delete_success_title": "Motorista excluido com sucesso!",
  "drivers.delete_many.message_error":
    "Falha ao excluir os motoristas selecionados",
  "drivers.delete.message_error": "Falha ao excluir o motorista",
  "drivers.empty_list.message":
    "Você ainda não tem nenhum motorista autorizado",

  "send.router":
    "Você tem certeza que deseja enviar esta roteirização para monitoramento?",

  "routing.add": "Criar nova tarefa manualmente",
  "routing.title": "Roteirização",
  "routing.upload_title": "Upload de Tarefas",
  "routing.tasks_title": "Tarefas Pendentes de Roteirização",
  "routing.tasks_button": "Selecionar e Rotereirizar",
  "routing.table_title": "Roteirizações Realizadas",
  "routing.table_date": "Data de criação",
  "routing.table_id": "ID",
  "routing.table_status": "STATUS",
  "routing.table_qtd_tasks": "TAREFAS",
  "routing.table_qtd_routes": "ROTAS",
  "routing.table_total_distance": "Km Total",
  "routing.table_user": "USUÁRIO",
  "routing.tasks_view_title": "Visualizar Tarefas",
  "routing.selected_tasks": "Tarefas Selecionadas para Roteirização",
  "routing.routing_start_button": "Iniciar Roteirização",
  "routing.table_task_id": "ID da tarefa",
  "routing.table_customer": "Cliente",
  "routing.table_address": "Endereço",
  "routing.table_type": "Tipo",
  "routing.table_weight": "Peso",
  "routing.table_volume": "Volume",
  "routing.table_value": "Valor",
  "routing.table_document_label": "Nº do Documento",
  "routing.divergence_error.type_service_required":
    "Tipo de serviço obrigatório – É necessário informar o tipo de serviço.",
  "routing.divergence_error.tag_not_found":
    "Tag não encontrada – A Tag procurada não foi encontrada.",
  "routing.divergence_error.valor_required":
    "Valor obrigatório – É necessário informar um valor.",
  "routing.divergence_error.valor_required_numeric":
    "Valor obrigatório numérico – O valor informado deve ser numérico.",
  "routing.divergence_error.valor_required_greater_than_or_equal_to_0":
    "Valor deve ser maior ou igual a 0 – O valor informado deve ser maior ou igual a zero.",
  "routing.divergence_error.volume_required":
    "Volume obrigatório – É necessário informar um volume.",
  "routing.divergence_error.volume_required_numeric":
    "Volume obrigatório numérico – O volume informado deve ser numérico.",
  "routing.divergence_error.volume_required_greater_than_or_equal_to_0":
    "Volume deve ser maior ou igual a 0 – O volume informado deve ser maior ou igual a zero.",
  "routing.divergence_error.peso_required":
    "Peso obrigatório – É necessário informar um peso.",
  "routing.divergence_error.peso_required_numeric":
    "Peso obrigatório numérico – O peso informado deve ser numérico.",
  "routing.divergence_error.peso_required_greater_than_or_equal_to_0":
    "Peso deve ser maior ou igual a 0 – O peso informado deve ser maior ou igual a zero.",
  "routing.divergence_error.address_required":
    "Endereço obrigatório – É necessário informar um endereço.",
  "routing.divergence_error.data_jurney_final_required":
    "Data final da jornada obrigatória – É necessário informar a data final da jornada.",
  "routing.divergence_error.data_jurney_start_required":
    "Data inicial da jornada obrigatória – É necessário informar a data inicial da jornada.",
  "routing.divergence_error.hour_jurney_final_required":
    "Hora final da jornada obrigatória – É necessário informar a hora final da jornada.",
  "routing.divergence_error.hour_jurney_start_required":
    "Hora inicial da jornada obrigatória – É necessário informar a hora inicial da jornada.",
  "routing.divergence_error.latitude_invalid":
    "Latitude inválida – A latitude informada não é válida.",
  "routing.divergence_error.longitude_invalid":
    "Longitude inválida – A longitude informada não é válida.",
  "routing.divergence_error.date_jurney_final_pickup_required":
    "Data final da coleta obrigatória – É necessário informar a data final da coleta.",
  "routing.divergence_error.date_jurney_start_pickup_required":
    "Data inicial da coleta obrigatória – É necessário informar a data inicial da coleta.",
  "routing.divergence_error.hour_jurney_final_pickup_required":
    "Hora final da coleta obrigatória – É necessário informar a hora final da coleta.",
  "routing.divergence_error.hour_jurney_start_pickup_required":
    "Hora inicial da coleta obrigatória – É necessário informar a hora inicial da coleta.",
  "routing.divergence_error.address_de_retirada_required":
    "Endereço de retirada obrigatório – É necessário informar o endereço de retirada.",
  "routing.divergence_error.latitude_pickup_invalid":
    "Latitude de coleta inválida – A latitude de coleta informada não é válida.",
  "routing.divergence_error.longitude_pickup_invalid":
    "Longitude de coleta inválida – A longitude de coleta informada não é válida.",

  "routing.inconsistent_address": "Endereço Inconsistente",
  "routing.send_success_title": "Roteirização enviada com sucesso.",
  "routing.send_success_message":
    "Acompanhe o processamento na tela inicial de roteirização ou clique aqui para consultar agora.",
  "routing.confirm_modal_popover":
    "Ao criar uma tarefa para roteirização futura, a mesma ficará disponível para ser incluída em uma nova roteirização.",
  "routing.confirm_modal_title": "Confirmar Roteirização",
  "routing.confirm_modal_subtitle":
    "Para confirmar sua roteirização, por favor, informe os dados abaixo e clique em confirmar.",
  "routing.start_location_label": "Local de Saída",
  "routing.start_location_select": "Selecione um local de saída",
  "routing.start_label": "Início",
  "routing.end_label": "Fim",
  "routing.location_title_modal": "Corrigir Endereço",
  "routing.location_import_address": "Endereço Importado",
  "routing.location_correct_address_label": "Endereço Correto",
  "routing.location_correct_address_enter": "Digite aqui o endereço correto",
  "routing.until": "até",
  "routing.filter_select_inconsistent_address_label":
    "Selecionar somente tarefas com endereço inconsistente",
  "routing.task_type_label": "Tipo de Tarefa",
  "routing.option_delivery": "Entrega",
  "routing.option_collect": "Coleta",
  "routing.option_service": "Serviço",
  "routing.option_collect_and_service": "Coleta + Entrega",

  "routing.customer_enter": "Digite o nome do cliente aqui",
  "routing.cod_customer_enter": "Digite o código do cliente aqui",
  "routing.service_window_label": "Janela de Atendimento",
  "routing.service_time_label": "Tempo de Atendimento",
  "routing.delete_selected": "Excluir Registros Selecionados",
  "routing.location_label": "Localização",
  "routing.observation_label": "Observações",

  "routing.phone_enter": "Digite aqui o telefone",
  "routing.document_label": "Nº do Documento",
  "routing.modal.confirm":
    "Ao criar uma tarefa para execução imediata, a mesma será alocada para o veículo mais próximo que atenda todas as características da tarefa.",
  "routing.document_enter": "Digite aqui o documento",
  "routing.collect_location_label": "Localização de Coleta",
  "routing.tags_popover":
    "Utilize tags para acrescentar restrições de operação para a tarefa. Exemplo: utilize a tag 'refrigerado' para que a tarefa somente seja alocada em um veículo refrigerado.",
  "routing.run_task_immediately_label": "Executar Tarefa Imediatamente",
  "routing.run_task_immediately_popover":
    "Ao criar uma tarefa para execução imediata, a mesma será alocada para o veículo mais próximo que atenda todas as características da tarefa.",
  "routing.request_prior_monitoring_validation_label":
    "Solicitar Validação Prévia do Monitoramento",
  "routing.request_prior_monitoring_validation_popover":
    "Tarefas prioritárias serão executadas antes das demais tarefas.",
  "routing.create_button_submit": "Criar",
  "routing.update_button_submit": "Atualizar",
  "routing.quantity_label": "Capacidade Utilizada",
  "routing.weight_label": "Peso",
  "routing.mandatory.field": "Campos marcados com o ",
  "field.mandatory": " são obrigatórios",
  "routing.modal.startdate":
    "Ao escolher a Data de Início a data deverá ser maior que Hoje",
  "routing.modal.finaldate":
    "Ao escolher a Data Final a data deverá ser maior que a data inicial",
  "routing.value_label": "Valor",
  "routing.amount_label": "Quantidade",
  "routing.material_label": "Material",
  "routing.material_type_select": "Selecione o Tipo de material",
  "routing.form_title": "Criar Tarefa Manualmente",
  "routing.confirm_delete_many_registers":
    "Você tem certeza que deseja excluir as tarefas selecionadas?",
  "routing.confirm_delete_register":
    "Você tem certeza que deseja excluir esta tarefa?",
  "routing.confirm_delete_message":
    "Ela será enviada para o modulo de roterização, onde poderá ser reprogramada.",
  "routing.date_start_enter": "Data de Início",
  "routing.date_end_enter": "Data de Fim",
  "routing.service_window_collect_label": "Janela de Atendimento de Coleta",
  "routing.edit.title": "Editar Tarefa",
  "routing.select.departure": "Local de Saída",
  "routing.select.arrival": "Local de chegada",
  "routing.select.departure.dropdown": "Selecione um local de saída",
  "routing.select.departure.message":
    " Para confirmar sua roteirização, por favor, informe os dados abaixo e clique em confirmar.",

  "routing.apartment_label": "Complemento",
  "routing.apartment_pickup_label": "Complemento de Retirada",
  "routing.phone_label": "Telefone",
  "routing.phone_pickup_label": "Captação de telefone",
  "routing.customer_label": "Cliente",
  "routing.customer_pickup_label": "Retirada do cliente",

  "routing.footer_amount_routes": "Quantidade de Rotas",
  "routing.footer_amount_jobs": "Quantidade de Tarefas",
  "routing.footer_amount_meters": "Km Total",
  "routing.footer_amount_amount": "Valor Total",
  "routing.footer_amount_weight": "Peso Total",
  "routing.footer_amount_km": "Volume Total",

  "routing.map_header_Creation_Date": "Data de Criação",
  "routing.map_header_Sending_Date": "Data de Envio",
  "routing.map_header_Status": "Status",
  "routing.map_header_User": "Usuário",

  "routing.modal.network_status": "Status das Redes",
  "routing.status.Roterization completed": "Roterização concluída",
  "routing.status.Sent to monitoring": "Enviado para monitoramento",
  "routing.status.Pending review": "Pendente de revisão",
  "routing.status.Roterization empty":
    "Roteirização sem rotas devido a tempo ou distância",
  "routing.status.Roterization failed - vroom":
    "Não foi possível processar essa roterizacao. Tente novamente mais tarde",

  "message.success.start_route":
    "Roteirização enviada com sucesso. Acompanhe o processamento na tela inicial de roteirização.",
  "configs.title": "Configurações",
  "configs.company.language_title": "Idioma e Configurações Regionais",
  "configs.company.country": "País",
  "configs.company.language": "Idioma",
  "configs.company.timezone": "Fuso Horário",
  "configs.company.company_title": "Empresa",
  "configs.company.company_name": "Nome da empresa",
  "configs.company.company_name_enter": "Digite o nome da empresa",
  "configs.company.cnpj": "CNPJ",
  "configs.company.street": "Rua",
  "configs.company.street_enter": "Digite a rua",
  "configs.company.number": "Número",
  "configs.company.complement": "Complemento",
  "configs.company.complement_enter": "Digite um complemento",
  "configs.company.neighborhood": "Bairro",
  "configs.company.neighborhood_enter": "Digite o bairro",
  "configs.company.state": "UF",
  "configs.company.city": "Cidade",
  "configs.company.zipcode": "CEP",
  "configs.company.state_label": "Estado",
  "configs.company.email": "E-mail de cobrança",
  "configs.company.email_enter": "Digite seu e-mail",
  "configs.company.save": "Salvar",
  "configs.company.cancel": "Cancelar",

  "configs.menu.company": "Empresa",
  "configs.menu.billing": "Faturamento",
  "configs.menu.manage_license": "Gerenciar licença",
  "configs.menu.go_to_checkout": "Ir para Checkout",
  "configs.menu.edit_contact": "Editar Contato para Fatura",
  "configs.menu.last_name": "Sobrenome",
  "configs.menu.edit_billing_address": "Endereço de Faturamento",
  "configs.menu.billing_iridium_connectivity": "Conectividade Iridium",
  "configs.menu.billing_connectivity": "Conectividade LoRaWAN",
  "configs.menu.journey_control": "Controle de Jornada",
  "configs.menu.driving_behavior": "Comportamento de Direção",
  "configs.menu.advanced_library": "Biblioteca CAN-BUS Avançada",
  "configs.menu.video_monitoring": "Videomonitoramento",
  "configs.menu.sensores_bluetooth": "Sensores Bluetooth",
  "configs.menu.routing_backoffice": "Roteirização e Backoffice",
  "configs.menu.plan_essential": "Essencial",
  "configs.menu.plan_advanced": "Avançado",
  "configs.menu.plan_security": "Segurança",
  "configs.menu.plan_professional": "Profissional",
  "configs.menu.chose_plan": "Escolher Plano",
  "configs.menu.current_plan": "Plano Atual",
  "configs.menu.monthly": "Mensal",
  "configs.menu.main_features": "Principais Funcionalidades",
  "configs.menu.fleet_tracking": "Rastreamento da Frota",
  "configs.menu.electronic_fences": "Cercas Eletrônicas",
  "configs.menu.travel_history": "Histórico de Viagens",
  "configs.menu.integration_api": "API de Integração",
  "configs.menu.delivery_control": "Controle de Entregas",
  "configs.menu.digital_forms": "Formulários Digitais",
  "configs.menu.security": "Segurança (Powered by FleetMate)",
  "configs.menu.invoice_date": "Histórico de Faturas",
  "configs.menu.date_hour": "DATA DA FATURA",
  "configs.menu.invoice": "FATURA",
  "configs.menu.value": "Valor",
  "configs.menu.status": "STATUS",
  "configs.menu.your_plan": "Seu plano",
  "configs.menu.licences": "Licenças",
  "configs.menu.by_licences": "Por licença",
  "configs.menu.by": "Por",
  "configs.menu.by_active_driver": "Por motorista ativo",
  "configs.menu.last_invoice": "Última Fatura",
  "configs.menu.manage_licenses": "Gerenciar Licenças",
  "configs.menu.manage_plan": "Gerenciar Plano",
  "configs.menu.estimate_of_the_next_invoice": "Estimativa da Próxima Fatura",
  "configs.menu.payment_method": "Método de Pagamento",
  "configs.menu.invoice_contact": "Contato para Fatura",
  "configs.menu.billing_address": "Endereço de Faturamento",
  "configs.menu.empty_table": "Nenhuma fatura encontrada",
  "configs.menu.sms_trigger": "Disparo de SMS",
  "configs.menu.exit_locations": "Local de Saída",
  "configs.menu.routing": "Opções de Roteirização",
  "configs.menu.users": "Usuários",
  "configs.menu.contractors": "Contratantes",
  "configs.menu.subcontractors": "Subcontratados",
  "configs.menu.sms": "Notificações de SMS",
  "configs.menu.app": "Aplicativo do Motorista",
  "configs.menu.mate": "FleetMate",
  "configs.menu.api": "API de Integrações",
  "configs.menu.audit": "Auditoria",
  "configs.menu.audit.filter_modal.select_type": "Selecione o tipo...",
  "configs.menu.audit.filter_modal.select_origin": "Selecione a origem...",
  "configs.menu.audit.filter_modal.select_action": "Selecione a ação...",
  "configs.menu.audit.download_modal":
    "O relatório foi gerado e será enviado por email.",
  "configs.menu.audit.no_records": "Não existem registros.",
  "configs.menu.webhook": "Webhooks",
  "sensors.audit.temperature": "Sensor de Temperatura",
  "sensors.audit.speed": "Sensor de Velocidade",
  "sensors.audit.fuel": "Sensor de Combustível",
  "sensors.audit.battery": "Sensor de Bateria",
  "sensors.audit.CO2": "Sensor de CO₂",
  "sensors.audit.camera": "Câmera",
  "sensors.audit.moisture": "Sensor de Umidade",

  "configs.user.add": "Novo Usuário ",
  "configs.user.edit_access_level": "Editar Níveis de Acesso",
  "configs.user.table_name": "NOME",
  "configs.user.table_email": "EMAIL",
  "configs.user.table_nivel": "NÍVEL DE ACESSO",
  "configs.user.table_status": "STATUS",
  "configs.user.table_enabled": "HABILITADO",
  "configs.user.reset_password": "Resetar Senha",
  "configs.user.user_delete": "Excluir Usuário",
  "configs.user.user_status_active": "Ativo",
  "configs.user.user_status_guest": "Convidado",
  "configs.user.invite_user": "Convidar Usuário",
  "configs.user.name": "Nome",
  "configs.user.name_enter": "Digite o nome",
  "configs.user.email": "Email",
  "configs.user.email_enter": "Digite o e-mail",
  "configs.user.access_level": "Nível de Acesso",
  "configs.user.access_level_enter": "Selecione um nível",
  "configs.user.access_level_view": "Visualização",
  "configs.user.access_level_edit": "Edição",
  "configs.user.access_level_security": "Segurança",
  "configs.user.access_level_commands": "Comandos",
  "configs.user.access_level_delete": "Deleção",
  "configs.user.configs.user.link_tags_plates": "Vincular Tags e Veículos",

  "configs.webhook.title": "Webhooks",
  "configs.webhook.actions.add": "Cadastrar Webhook",
  "configs.webhook.column.name": "Nome",
  "configs.webhook.column.type": "Tipo",
  "configs.webhook.column.method": "Método",
  "configs.webhook.column.status": "Status",
  "configs.webhook.column.url": "URL",
  "configs.webhook.column.actions": "Ações",
  "configs.webhook.url.copy": "Copiar",
  "configs.webhook.url.copy.success":
    "URL copiada para a área de transferência!",
  "configs.webhook.url.copy.error": "Erro ao copiar URL.",
  "configs.webhook.modal.type": "Tipo de Webhook",
  "configs.webhook.modal.name": "Nome do Webhook",
  "configs.webhook.modal.name.placeholder":
    "Exemplo:WEBHOOK ATUALIZAÇÃO DE JOB",
  "configs.webhook.modal.method": "Método",
  "configs.webhook.modal.url": "URL",
  "configs.webhook.modal.headers": "Headers",
  "configs.webhook.modal.headers.key": "Chave",
  "configs.webhook.modal.headers.value": "Valor",
  "configs.webhook.modal.headers.add": "Adicionar header",
  "configs.webhook.modal.active": "Ativar Webhook",
  "configs.webhook.modal.save": "Salvar",
  "configs.webhook.modal.saving": "Método",
  "configs.webhook.modal.cancel": "Cancelar",
  "configs.webhook.modal.details": "Detalhes",
  "configs.webhook.modal.close": "Fechar",

  "vehicles.title": "Veículos",
  "vehicles.button_add": "Cadastrar Veículo",
  "vehicles.new.title": "Cadastrar Veículo",
  "vehicles.edit.title": "Editar Veículo",
  "vehicles.table_title": "Novo Veiculo",
  "vehicles.table_type": "Tipo",
  "vehicles.table_board": "Placa",
  "vehicles.table_created_at": "Data de criação",
  "vehicles.table_price_by_km": "Custo por KM",
  "vehicles.table_hardware": "hardware",
  "vehicles.table_availability": "Disponibilidade",
  "vehicles.table_maximum_volume": "volume Máximo",
  "vehicles.table_maximum_weight": "Peso Máximo",
  "vehicles.table_maximum_value": "Valor Máximo",
  "vehicles.delete_many_text": "Excluir Registros Selecionados",
  "vehicles.confirm_delete_many_title":
    "Você tem certeza que deseja apagar os registros selecionados?",
  "vehicles.confirm_delete_title":
    "Você tem certeza que deseja excluir este veículo?",
  "vehicles.tab.hardware_title": "Hardware",
  "vehicles.tab.register_title": "Cadastro",
  "vehicles.form.label.license_plate": "Placa",
  "vehicles.form.label.license_plate.invalid":
    "A placa do veículo deve ser informada",
  "vehicles.form.label.vehicle_type": "Tipo de Veículo",
  "vehicles.form.label.vehicle_type.invalid":
    "O tipo do veículo deve ser informado",
  "vehicles.form.label.price_by_km": "Custo Por KM",
  "vehicles.form.label.active": "Disponibilizar Veículo",
  "vehicles.form.label.weight": "Peso",
  "vehicles.form.label.weight.invalid":
    "A capacidade máxima de peso deve ser informado",
  "vehicles.form.label.amount": "Valor",
  "vehicles.form.label.amount.invalid": "O valor máximo deve ser informado",
  "vehicles.form.label.tank": "Tanque",
  "vehicles.form.label.volume": "Volume",
  "vehicles.form.label.volume.invalid": "O volume máximo deve ser informado",
  "vehicles.form.select.material_type": "Selecione o Tipo de Material",
  "vehicles.form.label.tag": "Atributos",
  "vehicles.form.description.tag":
    "Os atributos dos veículos são utilizados para categorizar relatórios e incluir restrições na otimização de rotas.",
  "vehicles.form.label.observation": "Observações",
  "vehicles.form.enter.observation": "Digite aqui...",
  "vehicles.hardware.message.confirm_disable_sensor":
    "Você tem certeza que deseja desabilitar este sensor?",
  "vehicles.hardware.message.confirm_enable_sensor":
    "Você tem certeza que deseja habilitar este sensor?",
  "vehicles.hardware.message.confirm_send_command":
    "Você tem certeza que deseja enviar este comando?",
  "vehicles.hardware.send_command_satellite.label":
    "Enviar comando também por satélite.",
  "vehicles.hardware.message.success_send_command":
    "Comando enviado com sucesso. Será executado em breve.",
  "vehicles.hardware.title.error_send_command": "Falha na execução do comando",
  "vehicles.hardware.title.error_generate_actuator_code":
    "Falha ao gerar a contra senha do atuador",
  "vehicles.hardware.sensor.status_confirm.label": "Confirmação de Status",
  "vehicles.hardware.unlink_equipment": "Desvincular Equipamento",
  "vehicles.hardware.serial": "Número de Série",
  "vehicles.hardware.firmware": "Versão do Firmware",
  "vehicles.hardware.manufacturer": "Nome do Fabricante",
  "vehicles.hardware.model": "Modelo",
  "vehicles.hardware.sensor.title": "Sensores",
  "vehicles.hardware.actuator.title": "Atuadores",
  "vehicles.hardware.against_password.title": "Geração de Contra-Senha",
  "vehicles.hardware.disable_actuator.label":
    "Selecione o atuador que deseja desativar",
  "vehicles.hardware.button.generate": "Gerar",
  "vehicles.hardware.against_password_view":
    "Clique em gerar para visualizar a Contra-Senha",
  "vehicles.hardware.against_password_expires":
    "Essa Contra-Senha irá expirar em: ",
  "vehicles.hardware.video_monitoring.title": "Vídeo Monitoramento",
  "vehicles.hardware.camera.label": "Câmera",
  "vehicles.hardware.camera.last_update": "Última Atualização",
  "vehicles.hardware.camera.network_type": "Tipo de Rede",
  "vehicles.hardware.no_cameras": "Este veículo não possui câmeras",
  "vehicles.hardware.network_status.title": "Status da Rede",
  "vehicles.sensor.exit": "Saída",
  "vehicles.sensor.entry": "Entrada",
  "vehicles.empty_list.message": "Você ainda não tem nenhum veículo cadastrado",
  "vehicles.hardware.copy": "Copiar",
  "vehicles.hardware.copied": "Copiado!",
  "vehicles.hardware.sensor.status_active.label": "Ativo",
  "vehicles.hardware.sensor.status_inactive.label": "Inativo",
  "vehicles.video_monitoring": "Monitoramento",
  "vehicles.video_monitoring.last_update": "Última atualização",
  "vehicles.video_monitoring.network_type": "Tipo de rede",
  "vehicles.video_monitoring.hardware_information": "Informações do hardware",
  "vehicles.video_monitoring.imei": "IMEI",
  "vehicles.video_monitoring.sim_card": "Número do SIM Card",
  "vehicles.video_monitoring.model": "Modelo",
  vehicles_manufacturer_empty: "Sem Fabricante",
  associante_vehicle_modal_title: "Associação de Veículos",
  link_vehicle: "Associar veículo",
  unlink_vehicle: "Desassociar veículo",

  "configs.sms.back": "Voltar",
  "configs.sms.add": "Criar Nova Notificação",
  "configs.sms.table_enabled": "habilitado",
  "configs.sms.table_subject": "assunto",
  "configs.sms.table_time": "momento da notificação",
  "configs.sms.form_select": "Selecione...",
  "configs.sms.form_input_moment": "Em qual momento o cliente será notificado?",
  "configs.sms.form_label_sms_subject": "Qual será o assunto do SMS?",
  "configs.sms.form_label_sms_content": "Qual será o conteúdo do SMS?",
  "configs.sms.textarea_text": "caracteres restantes.",
  "configs.sms.field_title": "Campos de Preenchimento Automático",

  "configs.audit.title": "Auditoria",
  "configs.audit.add": "Nova Notificação",
  "configs.audit.filter_start_date": "Data de início do registro",
  "configs.audit.filter_end_date": "Data de término do registro",
  "configs.audit.table_register_date_hour": "DATA E HORA DO REGISTRO",
  "configs.audit.table_register_type": "TIPO DE REGISTRO",
  "configs.audit.table_event_date_hour": "DATA E HORA DO EVENTO",
  "configs.audit.table_user": "USUÁRIO",
  "configs.audit.table_driver": "DRIVER",
  "configs.audit.sensor.output_text": "Saída",
  "configs.audit.system_record": "Registros do Sistema",
  "configs.audit.positions": "Posições ",
  "configs.audit.messages": "Mensagens",
  "configs.audit.login": "Login",
  "configs.audit.electronic_fences": "Cercas Eletrônicas",
  "configs.audit.driver_app_settings":
    "Configurações do Aplicativo do Motorista",
  "configs.audit.commands": "Comandos",
  "configs.audit.command.expired": "Expirado",
  "configs.audit.command.received": "Recebido",
  "configs.audit.command.sent": "Enviado",
  "configs.audit.status": "Status",
  "configs.audit.register_date_hour": "Data e Hora do Registro",
  "configs.audit.event_date_hour": "Data e Hora do Evento",
  "configs.audit.datetime_execution": "Data e Hora da Execução",
  "configs.audit.expiration_datetime": "Data e Hora da Expiração",
  "configs.audit.confirmation_datetime": "Data e Hora da Confirmação",
  "configs.audit.login_datetime": "Data e Hora do Login",
  "configs.audit.datetime_receipt": "Data e Hora do Recebimento",
  "configs.audit.datetime_reading": "Data e Hora da Leitura",
  "configs.audit.date_reading": "Data da Leitura",
  "configs.audit.action": "Ação",
  "configs.audit.origin": "Origem",
  "configs.audit.user": "Usuário",
  "configs.audit.driver": "Motorista",
  "configs.audit.license_plate": "Placa do Veículo",
  "configs.audit.plate": "Placa",
  "configs.audit.command_origin": "Origem do Comando",
  "configs.audit.settings_origin": "Origem da Configuração",
  "configs.audit.network": "Rede",
  "configs.audit.rule": "Regra",
  "configs.audit.actuators": "Atuadores",
  "configs.audit.sensors": "Sensores",
  "configs.audit.electronic_fence_name": "Nome da Cerca Eletrônica",
  "configs.audit.sender": "Remetente",
  "configs.audit.recipient": "Destinatário",
  "configs.audit.message": "Mensagem",
  "configs.audit.speed": "Velocidade",
  "configs.audit.ignition": "Ignição",
  "configs.audit.battery_voltage": "Tensão da Bateria",
  "configs.audit.location": "Localização",
  "configs.audit.temperature_sensor": "Sensor de Temperatura",
  "configs.audit.changed_field": "Campo Alterado",
  "configs.audit.initial_registration": "Registro Inicial",
  "configs.audit.final_registration": "Registro Final",
  "audit.options.creation": "Criação",
  "audit.options.edition": "Exclusão",
  "audit.options.exclusion": "Edição",
  "configs.audit.logout": "Logout",
  "audit.options.settings": "Configurações",
  "audit.options.forms": "Formulários",
  "audit.options.alerts": "Alertas",
  "audit.options.driver_journey": "Jornada do Motorista",
  "audit.options.drivin_behavior": "Comportamento de Direção",
  "audit.options.vehicles": "Veículos",
  "audit.options.drivers": "Motoristas",
  "audit.options.optimization": "Otimização",
  "configs.audit.record_origin": "Origem do Registro",
  "configs.audit.record_type": "Tipo de Registro",
  "configs.audit.license_plate_label": "Veículo (Placa)",
  "configs.audit.create": "Criar",
  "configs.audit.update": "Atualizar",
  "configs.audit.delete": "Excluir",
  "configs.audit.create_geofence": "Criar Geofence",
  "configs.audit.run_command": "Executar comando no fleetmate",
  "configs.audit.setup_configurations": "Configurar configurações",
  "configs.audit.remove_geofences": "Remover geofences",
  "configs.audit.create_rule": "Criar regra de segurança",
  "configs.audit.remove_rule": "Remover regra de segurança",
  "configs.audit.fleetmate_sensors": "Sensores do fleetmate",
  "configs.audit.remove_registers": "Remover registros da fila",
  "journey.button_disabled_add_register": "Selecione um motorista",
  "calendar.january": "Janeiro",
  "calendar.february": "Fevereiro",
  "calendar.march": "Março",
  "calendar.april": "Abril",
  "calendar.may": "Maio",
  "calendar.june": "Junho",
  "calendar.july": "Julho",
  "calendar.august": "Agosto",
  "calendar.september": "Setembro",
  "calendar.october": "Outubro",
  "calendar.november": "Novembro",
  "calendar.december": "Dezembro",
  "calendar.sunday": "Domingo",
  "calendar.monday": "Segunda",
  "calendar.tuesday": "Terça",
  "calendar.wednesday": "Quarta",
  "calendar.thursday": "Quinta",
  "calendar.friday": "Sexta",
  "calendar.saturday": "Sabádo",

  "configs.exit_location.title": "Locais de Saída",
  "configs.exit_location.add": "Novo Local de Saída",
  "configs.exit_location.search": "Buscar",
  "configs.exit_location.table_name": "Nome",
  "configs.exit_location.table_address": "Endereço",
  "configs.exit_location.table_enabled": "Habilitado",
  "configs.exit_location.confirm_modal_title":
    "Você tem certeza que deseja excluir este local de saída?",
  "configs.exit_location.form_location_name": "Nome do Local",
  "configs.exit_location.form_placeholder_name": "Digite aqui o nome",
  "configs.exit_location.form_location": "Localização",
  "configs.exit_location.form_placeholder_location": "Digite aqui o endereço",
  "configs.exit_location.form_address": "Endereço",
  "configs.exit_location.form_coordinates": "Coordenadas",

  "search_box_places.coords": "Presione Enter para buscar as coordenadas",

  // Configs Routing Tabs
  "configs.routing.routing_rule_tab": "Regras de Roteirização",
  "configs.routing.vehicle_journey_tab": "Jornada dos Veículos",
  "configs.routing.capabilities_tab": "Capacidades",
  "configs.routing.types_material_tab": "Tipos de Material",

  // Configs Routing Rules
  "configs.routing.rule_title": "Regras de Roterização",
  "configs.routing.allow_vehicle_recharge": "Permitir a Recarga do Veículo",
  "configs.routing.grouping_deliveries_breakpoint":
    "Agrupar Entregas em Ponto de Parada",
  "configs.routing.create_open_routes": "Criar Rotas Abertas",
  "configs.routing.allow_dynamic_routing": "Permitir Roteirização Dinâmica",
  "configs.routing.allow_multi_day_routes": "Permitir Rotas de Múltiplos Dias",
  "configs.routing.optimization_standard": "Padrão de Otimização",
  "configs.routing.optimize_mileage": "Otimizar Quilimetragem",
  "configs.routing.optimize_service_time": "Otimizar Tempo de Atendimento",
  "configs.routing.traffic_conditions": "Condições do Trânsito",
  "configs.routing.slow": "Lento",
  "configs.routing.normal": "Normal",
  "configs.routing.fast": "Rápido",
  "configs.routing.measurement_system": "Sistema de Medidas",
  "configs.routing.metric_system": "Sistema Métrico (Quilômetros)",
  "configs.routing.imperial_system": "Sistema Imperial (Milhas)",

  // Configs Routing Vehicle journey
  "configs.routing.vehicles_journey_title": "Jornada dos Veículos",
  "configs.routing.start_journey": "Início da Jornada",
  "configs.routing.end_journey": "Fim da Jornada",
  "configs.routing.average_service_time": "Tempo Médio de Atendimento (TMA)",
  "configs.routing.maximum_tasks_per_route":
    "Quantidade Máxima de Tarefas por Rota",
  "configs.routing.include_lunch_break": "Incluir Intervalo de Descanço/Almoço",
  "configs.routing.first_interval": "Primeiro Intervalo",
  "configs.routing.second_interval": "Segundo Intervalo",
  "configs.routing.third_interval": "Terceiro Intervalo",
  "configs.routing.start_interval": "Início do Intervalo",
  "configs.routing.end_interval": "Fim do Intervalo",
  "configs.routing.optional": "Opcional",

  // Configs Routing Capability
  "configs.routing.capability_title": "Capacidade dos Veículos",
  "configs.routing.capability": "Capacidade",
  "configs.routing.tank": "Tanque",
  "configs.routing.enable_capability": "Habilitar Capacidade",
  "configs.routing.difine_transported_meterial":
    "Definir Material Transportado",
  "configs.routing.allocate_only_one_task_per_capacity":
    "Alocar Somente uma Tarefa por Capacidade",

  // Configs Routing Types Material
  "configs.routing.types_material_title": "Tipos de Material",
  "configs.routing.enter_material_type": "Digite um tipo de material",
  "configs.routing.new_material_type": "Novo Tipo de Material",
  "configs.routing.material_type.required": "Digite um tipo de material",

  // Configs Driver App Tabs
  "configs.app.general_settings_tab": "Configurações Gerais",
  "configs.app.driver_status_tab": "Status dos Motoristas",
  "configs.app.forms_tab": "Formulários",
  "configs.app.journey_control_tab": "Controle de Jornada",
  "configs.app.types_occurrence_tab": "Tipos de Ocorrências",

  // Configs Driver App Types Occurrence
  "configs.app.types_occurrence_title": "Tipo de Ocorrência",
  "configs.app.enter_occurrence_type": "Digite um tipo de ocorrência",
  "configs.app.new_occurrence_type": "Novo Tipo de Ocorrência",
  "configs.app.type_in.required": "Digite um tipo de ocorrência",
  "configs.app.occurrence_type_modal_title":
    "Você tem certeza que deseja excluir este tipo de ocorrência?",
  "configs.app.block_vehicle_ignition":
    "Bloquear a ignição do veículo no estouro da jornada",
  "configs.app.not_allow_driver_disable_voice":
    "Não permitir que o motorista desabilite as notificações de voz",

  // Configs Driver App journey Control
  "configs.app.journey_control_enable":
    "Habilitar Controle de Jornada dos Motoristas",
  "configs.app.journey_control_legislation":
    "Legislação de Controle de Jornada",
  "configs.app.journey_control_select": "Selecione...",

  // Configs Driver App Form
  "configs.app.form_title": "Novo Formulário",
  "configs.app.form_title_edit": "Editar Formulário",
  "configs.app.form_table_name": "Nome",
  "configs.app.form_table_required": "Preenchimento obrigatório",
  "configs.app.form_table_stage": "Etapa",
  "configs.app.form_table_enabled": "Habilitado",
  "configs.app.form_table_type": "Tipo de formulário",

  "configs.app.form_name": "Nome do Formulário",
  "configs.app.form_required": "Tornar este formulário obrigatório",
  "configs.app.question_without_title": "Pergunta sem título",
  "configs.app.option_type_short": "Resposta Curta",
  "configs.app.option_type_long": "Resposta Longa",
  "configs.app.option_type_multiple": "Multipla Escolha",
  "configs.app.option_type_selection": "Seleção",
  "configs.app.option_type_images": "Imagens",
  "configs.app.response_required": "Resposta Obrigatória",
  "configs.app.button_add_option": "Adicionar opção",
  "configs.app.button_new_question": "Nova pergunta",
  "configs.app.button_add": "Adicionar",
  "configs.app.button_cancel": "Cancelar",
  "configs.app.option_type_job": "Job",
  "configs.app.option_type_vehicle": "Veículo",

  // Configs Driver App Driver Status
  "configs.app.table_type_status": "Tipos de Status",
  "configs.app.table_journer_relation":
    "Vínculo do Status com o Controle de Jornada",
  "configs.app.type_status.required": "Tipo do Status é obrigatório",
  "configs.app.journey_entry.required": "Controle de jornada é obrigatório",
  "configs.app.select": "Selecione...",
  "configs.app.journer_select":
    "Selecione o vínculo do status com o controle de jornada...",
  "configs.app.enter_driver_status": "Digite um Status",
  "configs.app.new_driver_status": "Novo Status",
  "configs.app.disable_auto_detection":
    "Desabilitar a Detecção Automática do Status de Parado e Dirigindo",
  "configs.app.driver_status.modal_title":
    "Você tem certeza que deseja excluir este status?",
  "configs.app.driver_status.request_password":
    "Solicitar senha do motorista a cada novo status",

  // Configs Driver App General Settings
  "configs.app.general_settings.title": "Módulos do Aplicativo",
  "configs.app.general_settings.subtitle":
    "Selecione os módulos que serão habilitados para seus motoristas",
  "configs.app.general_settings.tasks": "Tarefas",
  "configs.app.general_settings.alerts": "Alertas",
  "configs.app.general_settings.driver_status": "Status dos Motoristas",
  "configs.app.general_settings.chat_messages": "Mensagens (Chat)",
  "configs.app.general_settings.ranking": "Ranking (Comportamento de Direção)",
  "configs.app.general_settings.peripherals": "Periféricos",
  "configs.app.general_settings.drivers_workflow":
    "Fluxo de Trabalho dos Motoristas",
  "configs.app.general_settings.proof_tasks":
    "Envio de Comprovante das Tarefas (Foto)",
  "configs.app.general_settings.shipping_required": "Envio Obrigatório",
  "configs.app.general_settings.type_occurrences":
    "Envio do Tipo das Ocorrências",
  "configs.app.general_settings.proof_occurrences":
    "Envio de Comprovante das Ocorrências (Foto)",
  "configs.app.general_settings.updated":
    "Configurações atualizadas com sucesso",

  "configs.api.title":
    "Aqui você pode criar chaves de API para ambientes de produção e Sandbox.",
  "configs.api.client_id": "Client ID",
  "configs.api.client_secret": "Gerar novo ClientSecret",
  "configs.api.production": "Nova chave de API em produção",
  "configs.api.sandbox": "Nova chave de API no sandbox",
  "configs.api.copy": "Copiar",

  "trips.title": "Viagens e Tarefas",
  "trips.table_id": "Id tarefa",
  "trips.table_date": "Data agendamento",
  "trips.table_status": "Status",
  "trips.table_driver": "Motorista",
  "trips.table_travelled_distance": "Km Percorrido",
  "trips.table_driving_time": "Tempo Diringindo",
  "trips.table_stops": "Tempo parado",
  "trips.table_alerts": "Alertas",
  "trips.table_task_details": "Ver detalhes",
  "trips.table_empty_list":
    "Selecione um veículo e as datas para visualizar o relatório",

  "trips.eye.finished_routes": "Rotas realizadas",
  "trips.eye.planned_routes": "Rotas planejadas",
  "trips.eye.waypoints": "Pontos de parada",

  "trips.pannel.qtd_vehicles": "Qtde. Veículos",
  "trips.pannel.average_speed": "Vel.Média",
  "trips.pannel.qtd_trips": "Qtde.Viagens",
  "trips.pannel.qtd_stop": "Qtde.Paradas",
  "trips.pannel.total_tasks": "Total de Tarefas",
  "trips.pannel.completed": "Concluídas",
  "trips.pannel.occurrence": "Ocorrências",
  "trips.pannel.partials": "Parciais",

  "trips.detail.title": "Detalhes das Tarefas",
  "trips.detail.table_doc": "Documentos",
  "trips.detail.table_status": "Status",
  "trips.detail.table_customer": "Cliente",
  "trips.detail.table_voucher": "Comprovante",
  "trips.detail.table_occurrence": "Ocorrência",
  "trips.detail.table_occurrence_date": "Data da ocorrência",
  "trips.detail.table_document_number": "Número de documento",
  "trips.detail.table_service_date": "Data atendimento",
  "trips.detail.table_duration": "Duração",
  "trips.detail.table_note_place": "Local de apontamento",
  "trips.detail.table_route_description": "Descrição de rota",
  "trips.detail.view_on_map": "Visualizar no mapa",

  "trips.task_detail.question": "Pergunta",
  "trips.task_detail.response": "Resposta",
  "trips.task_detail.customer": "Cliente",
  "trips.task_detail.doc": "Documento",
  "trips.task_detail.status": "Status",
  "trips.task_detail.address": "Endereço",
  "trips.task_detail.phone": "Telefone",
  "trips.task_detail.weight": "Peso",
  "trips.task_detail.volume": "Volume",
  "trips.task_detail.service_window": "Janela de atendimento (horário efetivo)",
  "trips.task_detail.expected_duration": "Duração prevista (duração efetiva)",
  "trips.task_detail.value": "Valor",
  "trips.task_detail.attributes": "Atributos",

  "sensors.title": "Sensor",
  "sensors.table_date": "Data",
  "sensors.table_hour": "Hora",
  "sensors.table_sensor": "Sensor",
  "sensors.table_type": "Tipo",
  "sensors.table_last_read": "Ultima Leitura",
  "sensors.table_vehicle": "Veiculo",
  "sensors.table_localization": "Localização",
  "sensors.table_driver": "Motorista",
  "sensors.table_alert": "Alerta",

  "sensor_detail.table_view_route": "Visualizar Rota",
  "sensor_detail.table_view_map": "Visualizar Localização",

  "journey.title": "Jornada dos Motoristas",
  "journey.drivers_title": "Motoristas",
  "journey.table_activity": "ATIVIDADE",
  "journey.table_duration": "DURAÇÃO ATV.",
  "journey.table_start": "INÍCIO",
  "journey.table_end": "FIM",
  "journey.table_location": "LOCALIZAÇÃO",
  "journey.table_overflow": "HORAS EXTRAS",
  "journey.table_extra": "HORAS ORDINÁRIAS",
  "journey.driver_name": "MOTORISTA",
  "journey.vehicle_license_plate": "VEÍCULO",
  "journey.table_alerts": "ALERTAS",
  "journey.cards.journey": "Jornada",
  "journey.cards.driving": "Dirigindo",
  "journey.cards.rest": "Descanço",
  "journey.cards.wait": "Espera",
  "journey.cards.lunch": "Almoço",
  "journey.cards.extra_hour": "Horas extras",
  "journey.cards.ordinary_extra_hour": "Horas ordinárias",
  "journey.add_register": "Adicionar Registro Manualmente",
  "journey.we_found": "Encontramos",
  "journey.registers": "registros",
  "journey.journey_reports": "Relatórios de Jornada",
  "journey.time_sheet": "Folha de Ponto",
  "journey.time_sheet_type": "Tipo de relatório de folha ponto",
  "journey.consolidated": "Consolidado",
  "journey.detailed": "Detalhado",
  "journey.archive_afd_afdt": "Arquivo AFD / AFDT",
  "journey.edit_records": "Registros de Edição",
  "journey.selected_driver": "Motorista Selecionado",
  "journey.all": "Todos",
  "journey.form_title_add": "Adicionar Registro",
  "journey.form_title_edit": "Editar Registro",
  "journey.form_title_delete": "Excluir Registro",
  "journey.form_vehicle_label": "Veículo",
  "journey.form_active_type": "Tipo de atividade",
  "journey.form_date_initial": "Início",
  "journey.form_date_final": "Fim",
  "journey.form_reason_label_add": "Motivo da Adição.",
  "journey.form_reason_label_edit": " Motivo da Edição.",
  "journey.form_reason_label_delete": "Motivo da Exclusão.",
  "journey.form_reason_enter": "Descreva aqui...",
  "journey.form_reason_characters": "caracteres restantes.",

  "configs.contractors.header.title": "Configurações de Contratantes",
  "configs.contractors.title": "Contratantes",
  "configs.contractors.table_contractor": "Contratante",
  "configs.contractors.table_vehicles": "Veículos Disponibilizados",
  "configs.contractors.table_disponibility": "Disponibilidade",
  "configs.contractors.table_advanced_control": "Controle Avançado",
  "configs.contractors.button.edit": "Editar Restrições",
  "configs.contractor.confirm_modal_title":
    "Você tem certeza que deseja excluir este contratante?",
  "configs.contractor.confirm_modal_message":
    "Essa ação não poderá ser revertida",
  "configs.contractors.form_restrictions_title": "Editar Restrições",
  "configs.contractors.form_restrictions_description":
    "Disponibilizar somente os veículos que possuem os atributos (TAG) listados.",
  "configs.contractors.form_label_tags": "Atributos (TAG)",
  "configs.contractor.empty_list": "Você ainda não tem nenhum Contratado",

  "configs.subcontractors.title": "Subcontratados",
  "configs.subcontractors.add": "Convidar Subcontratado",
  "configs.subcontractors.search": "Buscar",
  "configs.subcontractors.table_subcontractor": "Subcontratado",
  "configs.subcontractors.table_vehicle": "veículos agregados",
  "configs.subcontractors.table_email": "email",
  "configs.subcontractors.table_visibility": "VISIBILIDADE",
  "configs.subcontractors.table_status": "STATUS",

  "configs.subcontractors.form_title": "Convidar Subcontratado",
  "configs.subcontractors.form_name": "Nome",
  "configs.subcontractors.form_name_placeholder": "Digite o nome aqui...",
  "configs.subcontractors.form_email": "Email",
  "configs.subcontractors.form_email_placeholder": "Digite o email aqui...",
  "configs.subcontractor.empty_list": "Você ainda não tem nenhum Subcontratado",
  "configs.subcontractor.confirm_modal_title":
    "Você deseja ocultar os veículos subcontratados no sistema?",
  "configs.subcontractor.confirm_modal_message":
    "Essa ação não poderá ser revertida",
  "configs.subcontractor.confirm_approval_title":
    "Você deseja tornar os veículos liberados pelo subcontratado visíveis no sistema?",
  "configs.subcontractor.successfully_invited":
    "Subcontratado convidado com sucesso!",
  "configs.subcontractor.successfully_approved":
    "Veículos tornados visíveis com sucesso!",
  "configs.subcontractor.successfully_disapproved":
    "Veículos ocultados com sucesso!",
  "configs.subcontractor.active": "Ativo",
  "configs.subcontractor.inactive": "Inativo",
  "configs.subcontractor.awaiting_approval": "Aguardando aprovação",
  "configs.subcontractor.visibility": "Visibilidade",
  "configs.fleetmate.settings": "Configurações",
  "configs.fleetmate.embedded_intelligence": "Inteligência Embarcada",
  "configs.fleetmate.group_embedded_intelligence": "Grupos de configurações",
  "configs.fleetmate.ei.add_button": "Adicionar Conjunto de Regras",
  "configs.fleetmate.ei.add_button_new_rule": "Adicionar Regra",
  "configs.fleetmate.ei.add_button_new_setup": "Nova Configuração",
  "configs.fleetmate.ei.rule_name": "Nome da regra",
  "configs.fleetmate.ei.setup_name": "Nome da configuração",
  "configs.fleetmate.ei.qnt_vehicles": "Quantidade de veículos",
  "configs.fleetmate.ei.created_at": "Data de criação",
  "configs.fleetmate.ei.updated_at": "Última edição",
  "configs.fleetmate.ei.user": "Usuário",
  "configs.fleetmate.ei.qtd_events": "Número de eventos",
  "configs.fleetmate.ei.eligibility": "Habilitação",
  "configs.fleetmate.ei.action_vehicle_association": "Associação de Veículos",
  "configs.fleetmate.ei.label_from_data": "Data início",
  "configs.fleetmate.ei.label_to_data": "Data fim",
  "configs.fleetmate.ei.confirm_modal_title":
    "Você tem certeza que deseja excluir este registro",
  "configs.fleetmate.ei.confirm_modal_message":
    "Essa ação não poderá ser revertida",
  "configs.fleetmate.ei.rule_name_placeholder": "Digite um nome aqui...",
  "configs.fleetmate.ei.create_rule_using": "Criar Regra Utilizando:",
  "configs.fleetmate.ei.status_sensors": "Status dos Sensores",
  "configs.fleetmate.ei.status_driver": "Status do Motorista",
  "configs.fleetmate.ei.electronic_fences": "Cercas Eletrônicas",
  "configs.fleetmate.ei.sensors": "Sensor",
  "configs.fleetmate.ei.sensors_description":
    "Quais sensores acionarão a regra?",
  "configs.fleetmate.ei.status_sensor": "Status do Sensor",
  "configs.fleetmate.ei.status_sensor_description":
    "Qual status acionará a regra?",
  "configs.fleetmate.ei.status_driver_description":
    "Qual status do motorista acionará a regra?",
  "configs.fleetmate.ei.group_electronic_fences": "Gatilho da Cerca Eletrônica",
  "configs.fleetmate.ei.group_electronic_fences_description":
    "Quais grupos de cercas eletrônicas acionarão a regra?",
  "configs.fleetmate.ei.length_of_stay": "Tempo de Permanência",
  "configs.fleetmate.ei.electronic_fences_when": "Quando?",
  "configs.fleetmate.ei.electronic_fences_when_description":
    "Quando a regra será ativada",
  "configs.fleetmate.ei.when_the_rule_will_be_deactivated":
    "Quando a regra será desativada",
  "configs.fleetmate.ei.trigger_by_time_pt1":
    "Acionar a regra se o motorista não reportar um status após",
  "configs.fleetmate.ei.trigger_by_time_pt2": "minutos parado.",
  "configs.fleetmate.ei.actuators_label": "Atuadores",
  "configs.fleetmate.ei.actuators_description":
    "(Selecione as ações que a regra ira executar)",
  "configs.fleetmate.ei.fuel_blockage": "Bloqueio de Combustível",
  "configs.fleetmate.ei.chest_blockage": "Trava do Baú Princípal",
  "configs.fleetmate.ei.fifth_wheel_blockage": "Trava da Quinta Roda",
  "configs.fleetmate.ei.siren": "Sirene",
  "configs.fleetmate.ei.side_chest_blockage": "Trava do Baú Lateral",
  "configs.fleetmate.ei.wait_pt1": "Aguardar",
  "configs.fleetmate.ei.wait_pt2": "segundos na condição para acionar a regra",
  "configs.fleetmate.ei.generate_alert_monitoring":
    "Gerar Alerta no Monitoramento",
  "configs.fleetmate.ei.disable_jammer_detection":
    "Desabilitar Detecção de Jammer",
  "configs.fleetmate.ei.tags_label": "Tags",
  "configs.fleetmate.ei.tags_description":
    "(Utilize para aplicar a regra somente aos veículos associados as tags)",
  "configs.fleetmate.setting.interval_lorawan":
    "Intervalo de comunicação na Rede LoRaWAN",
  "configs.fleetmate.setting.interval_iridium":
    "Intervalo de comunicação na Rede Iridium",
  "configs.fleetmate.setting.ignition_on": "Ignição Ligada",
  "configs.fleetmate.setting.ignition_off": "Ignição Desligada",
  "configs.fleetmate.setting.entries": "Entradas",
  "configs.fleetmate.setting.entrie": "Entrada",
  "configs.fleetmate.setting.outputs": "Saídas",
  "configs.fleetmate.setting.output": "Saída",
  "configs.fleetmate.setting.driver_controls_exit":
    "Permitir ao motorista controlar a saída",
  "configs.fleetmate.setting.type": "Tipo",
  "configs.fleetmate.setting.type_encoded": "Saída Codificada",
  "configs.fleetmate.setting.type_decoded": "Não Codificada",
  "configs.fleetmate.setting.tolerance_time": "Tempo de Tolerância",
  "configs.fleetmate.setting.tolerance_for_rule_execution":
    "Tolerância para execução da regra",
  "configs.fleetmate.setting.seconds": "segundos",
  "configs.fleetmate.ei.vehicle_type": "Tipo de Veículo",
  "configs.fleetmate.ei.attributes_tag": "Atributos (TAG)",
  "configs.fleetmate.ei.vehicle_association": "Associação de Veículos",
  "configs.fleetmate.ei.vehicle": "Veículo",
  "configs.fleetmate.ei.vehicle_select": "Selecione um veículo...",
  "configs.fleetmate.ei.rule_drive_move":
    "Acionar a regra se o motorista deslocar o veículo mais de",
  "configs.fleetmate.ei.change_driver_status_to":
    "Alterar Status do Motorista para: ",
  "configs.fleetmate.ei.warning_the_driver": "Alertar o Motorista",
  "configs.fleetmate.ei.generate_alert_in_monitoring":
    "Gerar Alerta no Monitoramento",
  "configs.fleetmate.ei.sending_alerts_satellite":
    "Enviar Alerta pela Rede Satelital",
  "configs.fleetmate.ei.configs.fleetmate.ei.rule_drive_move":
    "Acionar a regra se o motorista deslocar o veículo mais de",

  "configs.fleetmate.ei.configs.error.new_driver_status":
    "É necessário informar o status do Motorista",

  "fleetmate.time.meters": "metros",
  "fleetmate.time.minute": "Minuto",
  "fleetmate.time.minutes": "Minutos",
  "fleetmate.entries.right_door": "Porta do Carona",
  "fleetmate.entries.left_door": "Porta do Motorista",
  "fleetmate.entries.fifth_wheel": "Quinta Roda",
  "fleetmate.entries.siren": "Sirene",
  "fleetmate.entries.fuel_blockage": "Bloqueio de Combustível",
  "fleetmate.entries.trunk_lock": "Trava do Báu",
  "fleetmate.entries.side_door": "Porta Baú Lateral",
  "fleetmate.entries.flush_door": "Porta Baú Principal",
  "fleetmate.entries.panel": "Painel",
  "fleetmate.entries.panel_narrow": "Tela de Janela",
  "fleetmate.entries.trailer_door": "Porta do trailer",
  "fleetmate.entries.coupling": "Engate",
  "fleetmate.entries.panic_button": "Pânico",
  "fleetmate.entries.ignition": "Ignição",
  "fleetmate.entries.flush_door_main": "Porta baú secundário",
  "fleetmate.outings.fuel_block": "Bloqueio de Combustível",
  "fleetmate.outings.side_door_lock": "Trava de Baú Lateral",
  "fleetmate.outings.flush_door_lock": "Trava de Baú Principal",
  "fleetmate.outings.fifth_wheel_lock": "Trava da Quinta Roda",
  "fleetmate.outings.sirem_alarm_lock": "Sirene",
  "fleetmate.outings.trailer_door_lock": "Trava do Trailer",
  "fleetmate.outings.ignition_lock": "Trava da Ignição",
  "fleetmate.sensor.side_chest": "Baú Lateral",
  "fleetmate.sensor.main_chest": "Baú Principal",
  "fleetmate.sensor.jammer_detection": "Detecção de Jammer",
  "fleetmate.sensor.hitch": "Engate",
  "fleetmate.sensor.panic": "Pânico",
  "fleetmate.sensor.right_door": "Porta do Carona",
  "fleetmate.sensor.left_door": "Porta do Motorista",

  "fleetmate.sensor.window_screen": "Tela de Janela",
  "fleetmate.sensor.silo": "Silo",
  "fleetmate.sensor.actived": "Ativado",
  "fleetmate.sensor.disabled": "Desativado",
  "fleetmate.efg.operation_base": "Base de Operação",
  "fleetmate.efg.client": "Cliente",
  "fleetmate.efg.authorized_stopping_point": "Ponto de Parada Autorizado",
  "fleetmate.efg.authorized_route": "Rota Autorizada",
  "fleetmate.efg.system_routes": "Rotas do Sistema (Otimização de Rotas)",
  "fleetmate.efg.jammer_interface_zone": "Zona de Interferência de Jammer",
  "fleetmate.efg.risk_zone": "Zona de Risco",

  "fleetmate.actuators.level_block": "Nível de Bloqueio",

  "fleetmate.geofence_base": "Base",
  "fleetmate.geofence_client": "Cliente",
  "fleetmate.geofence_risk_area": "Área de risco",
  "fleetmate.geofence_autorized": "Ponto de parada autorizado",

  "actuators.fuel_blockage": "Bloqueio de Combustível",
  "actuators.lock_main_chest": "Trava do Baú Princípal",
  "actuators.fifth_wheel_lock": "Trava da Quinta Roda",
  "actuators.lock_side_chest": "Trava do Baú Lateral",
  "actuators.siren": "Sirene",

  "sensor.side_chest": "Baú Lateral",
  "sensor.fifth_wheel": "Quinta Roda",
  "sensor.main_chest": "Baú Principal",
  "sensor.right_door": "Porta do Carona",
  "sensor.panel": "Painel",
  "sensor.panic": "Pânico",
  "sensor.ignition": "Ignição",
  "sensor.hitch": "Engate",

  "component.input_tag_new.placeholder": "Adicionar uma nova tag: ",
  "component.input_tag.placeholder": "Adicionar tag",
  "component.input_tag.emptylabel": "Nenhum resultado para exibir.",

  "configs.user.access_profile.title": "Editar Níveis de Acesso",
  "configs.user.access_profile.checkbox_all": "Selecionar Todos",
  "configs.user.access_profile.message.success":
    "Níveis de acesso editado com sucesso.",

  "message.success.save_data": "Dados salvos com sucesso!",
  "message.error.save_data": "Erro ao salvar os dados!",
  "message.error.ownuser": "Não é possivel alterar seus próprios dados!",
  "message.error.save_data.existplate":
    "Esta placa já esta cadastrada ou o formato da placa esta incorreto.",
  "message.success.delete_data": "Registro deletado com sucesso!",
  "message.error.delete_data": "Erro ao deletar os registro!",
  "message.success.delete_many_data": "Registros deletados com sucesso!",
  "message.error.delete_many_data": "Erro ao deletar os registros!",
  "message.action_not_reversed": "Essa ação não poderá ser revertida",
  "message.success.load_data": "Dados carregados com sucesso.",
  "message.error.load_data": "Falha ao carregar dados!",
  "message.failed.start.route":
    "Falha ao iniciar a roterização. Forneça mais detalhes e continue",

  "message.user_delete": "Você tem certeza que deseja excluir o usuário?",
  "message.user_reset_password":
    "Você tem certeza que deseja resetar a senha do usuário?",
  "message.user_successfully_reset_password": "Senha resetada com sucesso!",
  "message.user_error_reset_password": "Erro ao resetar senha!",
  "message.user_successfully_reset_password_send_email":
    "O usuário receberá um link no email cadastrado para a criação de uma nova senha.",
  "message.user_successfully_deleted": "Usuário deletado com sucesso!",
  "message.user_successfully_guest": "Usuário convidado com sucesso!",
  "message.user_receive_link":
    "O usuário receberá um link no email cadastrado.",
  "message.error_other_email": "Informe outro e-mail",
  "message.error_invalid_name": "Nome inválido",
  "message.error.date": "A data de término deve ser posterior à data de início",
  "fence_group.client": "Cliente",
  "fence_group.operation_base": "Base de Operação",
  "fence_group.risk_zone": "Zona de Risco",
  "fence_group.authorized_route": "Ponto de Parada Autorizado",
  "fence_group.authorized_stopping_point": "Rota Autorizada",

  "weekday.sunday": "Domingo",
  "weekday.monday": "Segunda-feira",
  "weekday.tuesday": "Terça-feira",
  "weekday.wednesday": "Quarta-feira",
  "weekday.thursday": "Quinta-feira",
  "weekday.friday": "Sexta-feira",
  "weekday.saturday": "Sábado",

  "electronic_fences.title": "Cercas Eletrônicas",
  "electronic_fences.new.title": "Nova Cerca Eletrônica",
  "electronic_fences.edit.title": "Editar Cerca Eletrônica",
  "electronic_fences.button_add": "Nova Cerca Eletrônica",
  electronic_fences: "Cerca Eletrônica",
  "electronic_fences.table_name": "Nome",
  "electronic_fences.table_type": "Tipo",
  "electronic_fences.table_group": "Grupo",
  "electronic_fences.table_created_at": "Data de Criação",
  "electronic_fences.table_qtd_alert": "Qntd. de Alertas",
  "electronic_fences.table_author": "Autor",
  "electronic_fences.table_enable": "Habilitar",
  "electronic_fences.initial_data": "Data de Ínício",
  "electronic_fences.final_data": "Data de Fim",
  "electronic_fences.action_associate_vhicles": "Associar Veículos",
  "electronic_fences.action_retroact": "Retroagir",
  "electronic_fences.action_edit": "Editar Cerca Eletrônica",
  "electronic_fences.action_delete": "Excluir Cerca Eletrônica",
  "electronic_fences.label_electronic_fence_name": "Nome da Cerca Eletrônica",
  "electronic_fences.enter_electronic_fence_name": "Digite aqui o nome...",
  "electronic_fences.label_fences_group": "Grupo de Cercas",
  "electronic_fences.select_fences_group": "Escolher um grupo...",
  "electronic_fences.title_electronic_fence_alerts":
    "Alertas de Cerca Eletrônica",
  "electronic_fences.label_alert_when": "Alertar quando:",
  "electronic_fences.title_hours": "Horários",
  "electronic_fences.label_period_from": "De",
  "electronic_fences.label_period_to": "Até",
  "electronic_fences.label_enter_fence": "Entrar na cerca",
  "electronic_fences.label_exit_fence": "Sair na cerca",
  "electronic_fences.label_stay_longer": "Permanecer mais que",
  "electronic_fences.label_report": "Informe",
  "electronic_fences.label_fence_minutes": "minutos na cerca",
  "electronic_fences.label_remain_less": "Permanecer menos que",
  "electronic_fences.label_fence_km": "km/h na cerca.",
  "electronic_fences.label_speed_higher": "Velocidade for superior a",
  "electronic_fences.label_email_notify": "Notificar via e-mail",
  "electronic_fences.label_notify_monitoring": "Notificar no monitoramento",
  "electronic_fences.label_email_address": "Endereço de e-mail",
  "electronic_fences.label_email_address_detail":
    "• Separados por vírgula • Até 5",
  "electronic_fences.label_tags":
    "Atributos (Somente gerar alertas para veículos com os seguintes atributos)",
  "electronic_fences.map_option_circle": "Círcular",
  "electronic_fences.map_option_polygonal": "Poligonal",
  "electronic_fences.map_option_route": "Rota",
  "electronic_fences.map_import_route": "Importar Trajeto",
  "electronic_fences.map_import_stop_point": "Importar Pontos de Parada",
  "electronic_fences.map_new_circle": "Criar Cerca Círcular",
  "electronic_fences.map_new_polygonal": "Criar Cerca Poligonal",
  "electronic_fences.map_new_route": "Criar Cerca Rota",
  "electronic_fences.map_info_import_route":
    "Utilize esta opção para importar todas as referências da rota usada. Opção recomenda quando não quiser utilizar o trajeto calculado pelo sistema.",
  "electronic_fences.map_info_import_stop_point":
    "Utilize esta opção para importar somente as referências dos pontos de parada da rota. Nesta opção o trajeto será calculado automaticamente pelo sistema.",
  "electronic_fences.map_label_address": "Endereço",
  "electronic_fences.map_label_radius": "Raio (Km)",
  "electronic_fences.map_enter_address": "Digite aqui o endereço...",
  "electronic_fences.map_import_references": "Importar Referências",
  "electronic_fences.map_download_template": "Baixar Template",
  "electronic_fences.map_add_route": "Adicionar Destino",
  "electronic_fences.map_label_perimeter": "Perímetro (Km)",
  "electronic_fences.map_maintain_association_from": "Manter associação de",
  "electronic_fences.map_maintain_association_to": "até",
  "electronic_fences.map_label_membership_indefinitely":
    "Manter associação indefinidamente.",
  "electronic_fences.select_author": "Selecione o autor...",
  "electronic_fences.select_type": "Selecione o tipo...",
  "electronic_fences.show_enabled_fences": "Exibir somente cercas habilitadas",
  "electronic_fences.confirm_delete_title":
    "Você tem certeza que deseja excluir esta cerca eletrônica?",

  "traking.customize_grid": "Customizar Grid",
  "traking.option_path": "Trajeto",
  "traking.option_vehicle": "Veículos",
  "traking.option_customer": "Clientes",
  "traking.option_electronic_fence": "Cercas Eletrônicas",
  "traking.filter_vehicle_type": "Tipo de Veículo",
  "traking.filter_vehicle": "Veículo",
  "traking.filter_driver": "Motorista",
  "traking.filter_driver_status": "Status do Motorista",
  "traking.filter_route_description": "Descrição da rota",
  "traking.filter_geofence": "Geofence",
  "traking.filter_select_label": "Selecione o Filtro",
  "traking.filter_initial_date": "Início",
  "traking.filter_final_date": "Fim",
  "traking.filter_add_new": "Novo Filtro",
  "traking.customize_grid_subtitle":
    "Selecione e ordene os itens que irão aparecer no Grid.",
  "traking.table_route_description": "Descrição da rota",
  "traking.table_timeline_tasks": "Timeline de Tarefas",
  "traking.table_route": "Rota",
  "traking.table_plate": "Placa",
  "traking.table_status": "Status",
  "traking.table_battery": "Bateria",
  "traking.table_left_door": "Porta do motorista",
  "traking.table_right_door": "Porta do carona",
  "traking.table_fifth_wheel": "Quinta roda",
  "traking.table_side_door": "Porta Baú lateral",
  "traking.table_flush_door": "Porta Baú traseira",
  "traking.table_panel": "Painel",
  "traking.table_panel_narrow": "Tela de janela",
  "traking.table_coupling": "Engate",
  "traking.table_trailer_door": "Porta do trailer",
  "traking.table_panic_button": "Pânico",
  "traking.table_ignition": "Ignição",
  "traking.table_silo": "Silo",
  "traking.table_fuel_block": "Bloqueio de combustível",
  "traking.table_side_door_lock": "Trava de baú lateral",
  "traking.table_flush_door_lock": "Trava de baú traseira",
  "traking.table_fifth_wheel_lock": "Trava da quinta roda",
  "traking.table_sirem_alarm_lock": "Sirene",
  "traking.table_trailer_door_lock": "Trava do trailer",
  "traking.table_flush_door_main": "Porta baú principal",
  "traking.table_macaddress": "Endereço Mac",
  "traking.table_location": "Localização",
  "traking.table_driving_time": "Tempo Dirigindo",
  "traking.table_stoping_time": "Tempo Parado",
  "traking.table_engine_time": "Tempo de Motor Ocioso",
  "traking.table_address": "Endereço",
  "traking.table_speed": "Velocidade",
  "traking.table_options": "Opções",
  "traking.table_geofence": "Geofence",
  "traking.btn_hidden": "Esconder",
  "traking.msg_box": "Caixa de Mensagens",
  "traking.notification_center": "Central de Notificações",
  "traking.center": "Central",
  "traking.btn_all_read": " Marcar todas como lidas",
  "traking.btn_add_comment": "Adicionar comentário",
  "traking.btn_mark_read": "Marcar como lida",
  "traking.label_comment": "Comentário",
  "traking.enter_message": "Mensagem...",
  "traking.task_detail_customer": "Cliente",
  "traking.task_detail_phone": "Telefone",
  "traking.task_service_window": "Janela de Atendimento",
  "traking.task_service_time": "Tempo de Atendimento",
  "traking.table_document": "Documento",
  "traking.table_image": "Imagem",
  "traking.task_eta_provided": "Previsto",
  "traking.task_eta_accomplished": "Realizado",
  "traking.task_previous": "Anterior",
  "traking.task_next": "Próximo",
  "traking.status_pending": "Pendente",
  "traking.status_completed": "Concluído",
  "traking.status_partial": "Parcial",
  "traking.attached_image": "Imagem anexa",
  "traking.attached_doc": "Document anexo",
  "traking.task_detail_vehicle": "Veiculo",
  "traking.task_detail_driver": "Motorista",
  "traking.task_detail_cell_phone": "Celular",
  "traking.task_detail_route_id": "ID Rota",
  "traking.task_detail_total_value": "Valor total",
  "traking.task_detail_volume_total": "Volume total",
  "traking.task_detail_weight_total": "Peso total",
  "traking.task_detail_journey": "Jornada",
  "traking.task_detail_total_route_km": "Km Total da Rota",
  "traking.task_detail_start_of_route": "Início da Rota",
  "traking.task_detail_end_of_route": "Fim da Rota",
  "traking.task_detail_fuel_consumption": "Consumo de combustível/km",
  "traking.task_detail_fuel_total": "Combustível total",
  "traking.task_detail_total_toll": "Pedágio total",
  "traking.task_network_status": "Status da Rede",
  "traking.task_actuators": "Atuadores",
  "traking.task_sensors": "Sensores",
  "traking.notifify_review": "Revisar",
  "traking.notify_comment": "Comentar",
  "traking.footer_number_routes": "Quantidade de Rotas",
  "traking.footer_completed_tasks": "Tarefas Concluídas",
  "traking.footer_occurrences": "Ocorrências",
  "traking.footer_delays": "Atrasos",
  "traking.footer_average_service_time": "Tempo Médio de Atendimento",
  "traking.footer_average_speed": "Velocidade Média",
  "traking.task_type_label": "Tipo de tarefa",
  "traking.task_review_observation_label": "Observações",
  "traking.task_review_documents_label": "Documentos",
  "traking.task_review_driver_label": "Motorista",
  "traking.task_review_allocated_vehicle_label": "Veículo Alocado",
  "traking.task_review_weight_label": "Peso",
  "traking.task_review_volume_label": "Volume",
  "traking.task_review_value_label": "Valor",
  "traking.task_review_exit_point_label": "Distância do Ponto de Saída",
  "traking.task_review_task_location_label": "Distância do Local da Tarefa",
  "traking.task_review_title": "Revisar Tarefa",
  "traking.task_comment_title": "Adicionar Comentário",
  "traking.task_comment": "Comentário",
  "traking.task_task_type": "Tipo de Tarefa",
  "traking.table_km": "Km",
  "traking.table_total_weight": "Peso",
  "traking.table_total_amount": "Volume",
  "traking.table_total_value": "Valor",
  "traking.table_last_date_comunication_vehicle":
    "Última Comunicação do Veículo",
  "traking.table_last_date_update_gps_vehicle": "Última Atualização do GPS",
  "traking.table_time_stoped": "Tempo parado",
  "traking.table_idle_engine_time": "Tempo de motor ocioso",
  "traking.task.modal_locked": "Fechada",
  "traking.task.modal_open": "Aberta",
  "traking.task.modal_blocked": "Bloqueado",
  "traking.task.modal_unblocked": "Desbloqueado",
  "traking.task.modal_fifth_wheel_blocked": "Engatado",
  "traking.task.modal_fifth_wheel_unblocked": "Desengatado",
  "traking.task.modal_off": "Desligada",
  "traking.task.modal_on": "Ligada",
  "traking.task.modal_disabled": "Desativado",
  "traking.task.modal_enabled": "Ativado",
  "traking.task.modal_lock": "Fechar",
  "traking.task.modal_unlock": "Abrir",
  "traking.task.modal_block": "Bloquear",
  "traking.task.modal_unblock": "Desbloquear",
  "traking.task.modal_turnoff": "Desligar",
  "traking.task.modal_turnon": "Ligar",
  "traking.task.modal_disable": "Desativar",
  "traking.task.modal_enable": "Ativar",
  "traking.task.modal_sensors_entry": "Sensores (Entradas)",
  "traking.task.modal_actuators_exit": "Atuadores (Saídas)",
  "traking.task.modal_executing": "Comando em Execução",
  "traking.task.modal_current_status": "Status Atual",
  "traking.task.modal_executed": "Comando Executado",
  "traking.task.modal_network_error":
    "Todas as redes de comunicação estão offline.",
  "traking.task.modal_attendance_window": "à",
  "traking.task.modal_arrival_time": "Horário de Chegada",
  "traking.task.modal_phone_number": "Número de Telefone",
  "traking.notifications.modal_today": "Hoje",
  "traking.notifications.modal_yesterday": "Ontem",

  "traking.routing.error_document_duplicated":
    "O número de documento já existe",
  "traking.routing.error_document_invalid": "O número do documento é inválido",
  "traking.routing.error_task_type_invalid": "O tipo de tarefa é invalido",
  "traking.routing.error_vehicle_invalid": "Dados de veículos inválidos",
  "traking.routing.error_cod_customer_invalid": "Código de cliente inválido",
  "traking.routing.error_customer_invalid": "Nome do cliente inválido",
  "traking.routing.error_address_invalid":
    "O endereço está incorreto. Por favor cheque se ele está correto e/ou existe.",
  "traking.routing.error_weight_invalid": "Peso inválido",
  "traking.routing.error_volume_invalid": "Volume inválido",
  "traking.routing.error_amount_invalid": "Valor inválido",
  "traking.routing.error_input_invalid_details":
    "Algum valor de entrada está vazio, incorreto ou inválido",
  "traking.routing.error_failed_routerization": "Falha na roterização",
  "traking.routing.error_failed_routerization_details":
    "Por favor cheque as informações de endereço e cliente e verifique se são válidas",

  "driving_behavior.title": "Comportamento de Direção",
  "driving_behavior.subtitle": "Motoristas",
  "driving_behavior.table_ranking": "Ranking",
  "driving_behavior.table_driver": "Motorista",
  "driving_behavior.table_km_traveled": "Km Percorrido",
  "driving_behavior.table_driving_hours": "Horas de Direção",
  "driving_behavior.table_acceleration": "Aceleração",
  "driving_behavior.table_braking": "Frenagem",
  "driving_behavior.table_cell_phone": "Uso do celular",
  "driving_behavior.table_search_underwear": "Curva de busca",
  "driving_behavior.table_speeding": "Excesso de Velocidade",
  "driving_behavior.filter_driver_enter": "Selecione o motorista...",
  "driving_behavior.filter_ranking_label_between": "Entre",
  "driving_behavior.filter_ranking_label_and": "e",
  "driving_behavior.detail_underwear": "Curva",
  "driving_behavior.detail_speed": "Velocidade",
  "driving_behavior.detail_driving_time": "Tempo de Direção",
  "driving_behavior.detail_average_text": "Média Geral",

  "sensors.table_datetime": "Data e Hora",
  "sensors.table_location": "Localização",
  "sensors.alert_rules_button_text": "Regras de Alerta de Sensores",
  "sensors.view_location": "Visualizar Localização",
  "sensors.option_edit_rule": "Editar regra",
  "sensors.option_delete_rule": "Excluir regra",
  "sensors.new_alert_rule_sensor": "Criar Nova Regra de Alerta de Sensor",
  "sensors.rule.table_created_at": "Data de criação",
  "sensors.rule.table_rule_name": "Nome da regra",
  "sensors.rule.table_ruleset_name": "Nome do grupo de regras",
  "sensors.rule.table_sensor_type": "Tipo de sensor",
  "sensors.rule.table_user": "Usuário responsável",
  "sensor_detail.table_date_time": "Data e Hora",
  "sensor_detail.table_vehicle": "Veículo",
  "sensor_detail.table_read": "Leitura",
  "sensor_detail.table_battery": "Bateria",
  "sensor_detail.table_route": "Rota",
  "sensor_detail.table_location": "Localização",
  "sensor_detail.table_driver": "Motorista",
  "sensor_detail.table_alert": "Alertas",
  "sensors.sonsor_id": "ID do Sensor",
  "sensors.chart_label": "Nível de Alerta",
  "sensors.temperature": "Temperatura",
  "sensors.speed": "Velocidade",
  "sensors.fuel": "Combustível",
  "sensors.battery": "Bateria",
  "sensors.CO2": "CO₂",
  "sensors.camera": "Câmera",
  "sensors.moisture": "Umidade",
  "sensors.last_update": "Última atualização:",
  "sensors.filter_last_read_label": "Última Leitura:",
  "sensors.filter_start_date": "Início",
  "sensors.filter_end_date": "Fim",
  "sensors.filter_sensor_type": "Tipo de Sensor",
  "sensors.form_alert_name": "Nome do Alerta",
  "sensors.form_alert_name_enter": "Digite aqui o nome do alerta",
  "sensors.form_type": "Tipo",
  "sensors.form_send_alert_label": "Emitir alerta quando for:",
  "sensors.form_option_rule_more_then": "Maior que",
  "sensors.form_option_rule_less_then": "Menor que",
  "sensors.form_option_rule_percent": "Variação percentual",
  "sensors.form_rule_enter": "Informe aqui...",
  "sensors.form_option_operator_and": "e",
  "sensors.form_option_operator_or": "ou",
  "sensors.form_add_condition": "Adicionar condição",
  "sensors.form_change_on": "Alertar quando:",
  "sensors.form_receive_event": "No recebimento do evento",
  "sensors.form_weekdays_label": "Dias da semana",
  "sensors.form_issue_alert_when_label": "Emitir alerta quando for",
  "sensors.form_from": "De:",
  "sensors.form_to": "Até:",
  "sensors.form_and": "e",
  "sensors.form_notify_email": "Notificar via e-mail",
  "sensors.form_notify_traking": "Notificar no monitoramento",
  "sensors.form_notify_sms": "Notificar por SMS",
  "sensors.form_alert_more_then_enter": "Informe",
  "sensors.form_alert_more_then": "Alertar somente quando permanecer mais que",
  "sensors.form_condition_seconds": "segundos na condição.",
  "sensors.form_inhibit_alerts_within_references":
    "Inibir alertas dentro de referências cadastradas base.",
  "sensors.form_inhibit_alerts_within_references_customer":
    "Inibir alertas dentro de referências de cliente.",
  "sensors.form_inhibit_alerts_vehicles_not_associate":
    "Inibir alertas quando o veículo não tiver tarefa associada.",
  "sensors.form_title": "Criar Alerta de Sensor",
  "sensors.status_enabled": "Ativado",
  "sensors.status_disabled": "Desativado",
  "sensors.value_open": "Aberto",
  "sensors.value_close": "Fechado",
  "sensors.unlinked_vehicle": "Veículo desassociado com sucesso",
  "sensors.linked_vehicle": "Veículo associado com sucesso",

  "alerts.alert_title": "Alertas",
  "alerts.description": "Descrição",
  "alerts.table_event_date": "Data do evento",
  "alerts.table_alert": "Alerta",
  "alerts.table_type_alert": "Tipo de alerta",
  "alerts.table_reading": "Leitura",
  "alerts.table_vehicle": "Veículo",
  "alerts.table_route": "Rota",
  "alerts.table_location": "Localização",
  "alerts.table_driver": "Motorista",
  "alerts.filter_initial_date_label": "Data de Início",
  "alerts.filter_end_date_label": "Data de Fim",
  "alerts.option_add_comment": "Adicionar Comentário",
  "alerts.option_view_comments": "Visualizar Comentários",
  "alerts.view_comments_title": "Visualizar Comentários",
  "alerts.comments_not_found": "Nenhum comentário encontrado!",
  "alerts.option_view_media": "Visualizar Mídias",
  "alerts.view_media": "Visualizar Mídias",
  "alerts.table_videos": "Vídeos",
  "alerts.video_label": "Vídeo",
  "alerts.table_images": "Imagens",
  "alerts.image_label": "Imagem",
  "alerts.filter_vehicle_type_label": "Tipo de Veículo",
  "alerts.filter_alert_type_label": "Tipo de Alerta",
  "alerts.filter_vehicle_label": "Veículo",
  "alerts.confirm_delete_title":
    "Você tem certeza que deseja excluir este alerta?",

  "electronic_fences.upload_file": "Enviar arquivo de configuração de cercas",
  "electronic_fences.select_file":
    "selecionar arquivo de configuração de cercas",

  "configs.group.name_input": "Insira um nome para descrever a configuração",
  "jorney.no_end_jorney": "Sem Fim de Jornada",

  "reports.title": "Central de relatórios",
  "reports.loading": "Carregando central de relatórios",
  "reports.success": "Successo",
  "reports.failure": "Falha",
  "reports.description": "Descrição",
  "reports.status": "Status",
  "reports.created_at": "Criação",
  "reports.user_id": "Usuário",
  "reports.completed_at": "Finalizado",
  "reports.download": "Baixar",

  "reports.status.requested": "Requisitado",
  "reports.status.processing": "Processando",
  "reports.status.success": "Concluído",
  "reports.status.fail": "Falhou",

  "sensors_generatexls_modal.filter": "Filtrar",
  "sensors_generatexls_modal.start_date": "Data de início",
  "sensors_generatexls_modal.end_date": "Data de término",
  "sensors_generatexls_modal.generate_excel": "Gerar Excel",
  "sensors_generatexls_modal.loading": "Carregando...",
  "sensors_generatexls_modal.error.invalid_date":
    "Data inválida. Por favor, insira uma data no formato correto.",
  "sensors_generatexls_modal.error.date_range_exceeded":
    "O intervalo entre as datas não pode exceder 1 mês.",
  "sensors_generatexls_modal.error.start_after_end":
    "A data de início não pode ser posterior à data de término.",

  "sensors.toast.report_available.prefix":
    "Relatório disponível na central de relatórios!",
  "sensors.toast.report_available.link_text": "Clique aqui para mais detalhes.",
  "sensors.toast.error_occurred": "Ocorreu um erro. Tente novamente.",
};
