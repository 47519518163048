import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Modal } from "./styles";
import { useForm } from "react-hook-form";
import { Skeleton } from "../Skeleton";
import { VehicleService } from "../../core/services/VehicleService";
import { isErrorResult } from "../../core/utils/api";
import {
  ISetVehicleEntry,
  SensorsService,
} from "../../core/services/SensorsService";
import { Sensor } from "../../core/interfaces/Sensors";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import { updateAlert } from "../../core/redux/reducer/alert";
import { Vehicle } from "../../core/interfaces/Vehicle";
import { AsyncPaginate } from "react-select-async-paginate";
import { getUser } from "../../core/redux/reducer/auth";

type FormValues = {
  vehicles: { id: number; label: string; checked: boolean }[];
  activity: string;
  reason: string;
  period: { from: string; to: string };
  lat: string;
  long: string;
};

interface AssocianteVehicleFormModalProps {
  visible: boolean;
  onClose?(): void;
  onFinishRequest: () => void;
  sensor: Sensor;
}

const SMS_TEXT_LIMIT = 250;

interface IValues {
  vehicles: Vehicle[];
  selected_vehicles: {
    label: string;
    value: string;
  };
  vehicle_id: number | null;
  isLoading: boolean;
}

const AssociateVehicleModal = ({
  onClose,
  visible,
  onFinishRequest,
  sensor,
}: AssocianteVehicleFormModalProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const user = useAppSelector(getUser);

  const {
    formState: { errors },
  } = useForm<FormValues>();

  const [values, setValues] = useState<IValues>({
    vehicles: [],
    selected_vehicles: {} as any,
    vehicle_id: sensor.vehicle_id,
    isLoading: false,
  });

  const [pagination, setPagination] = useState<{
    page: number;
    pages: number;
    filter: any;
    order: { [key: string]: string };
  }>({ page: 1, pages: 1, filter: "", order: { date: "desc" } });

  function updateValues({ name, value }: { name: string; value: any }) {
    setValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
  }

  function handleCancel() {
    onClose?.();
  }

  async function handleSetVehicle({
    license_plate,
    sensor_id,
  }: ISetVehicleEntry) {
    await SensorsService.setVehicle({
      license_plate,
      sensor_id,
    });

    onFinishRequest();
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!values.selected_vehicles) {
      return;
    }
    updateValues({ name: "isLoading", value: true });
    try {
      //call the api
      await handleSetVehicle({
        license_plate: values.selected_vehicles.label,
        sensor_id: sensor.sensor_id,
      });

      updateValues({ name: "errors", value: [] });

      onClose?.();

      onFinishRequest();

      dispatch(updateAlert({ title: t("sensors.linked_vehicle") }));
    } catch (err: any) {
      updateValues({ name: "errors", value: [t("journey.form_error")] });
      console.error(err);

      const errorsObject = err?.response?.data?.errors;

      const _errors =
        errorsObject && Object.keys(errorsObject).length > 0
          ? Object.values(errorsObject)
              .map((error: unknown) => error as string)
              .join(", ")
          : "Error";
      dispatch(updateAlert({ title: _errors, type: "error" }));
    } finally {
      updateValues({ name: "isLoading", value: false });
    }
  };

  useEffect(() => {
    async function getAsyncData() {
      updateValues({ name: "isLoading", value: true });

      const vehicles = await VehicleService.get({});
      const isErrorOnVehicles = isErrorResult(vehicles);
      updateValues({ name: "selected_vehicles", value: sensor.vehicle });
      if (!isErrorOnVehicles) {
        updateValues({ name: "vehicles", value: vehicles.data });
      }
      updateValues({ name: "isLoading", value: false });
    }
    if (visible) {
      getAsyncData();
    }
  }, [visible]);

  return (
    <Modal show={visible} size="lg" centered onHide={onClose}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title>{t("associante_vehicle_modal_title")}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4 pt-0">
        <Form onSubmit={onSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="vehicles">
              <Form.Label>{t("journey.form_vehicle_label")}</Form.Label>
              <Skeleton isLoading={values.isLoading} height="40px">
                <Form.Group>
                  <AsyncPaginate
                    key={user?.company_id}
                    classNamePrefix=""
                    placeholder={t("input.select_text")}
                    defaultValue={() => {
                      const defaultSensor = {
                        label: sensor.vehicle?.license_plate,
                        value: sensor.vehicle_id,
                      };
                      updateValues({
                        name: "selected_vehicles",
                        value: defaultSensor,
                      });
                      return defaultSensor;
                    }}
                    loadOptions={(inputValue, options, additional) =>
                      VehicleService.loadVehicleOptions(
                        inputValue,
                        options,
                        additional
                      )
                    }
                    additional={{ page: 1 }}
                    onChange={(selectedOptions) => {
                      updateValues({
                        name: "selected_vehicles",
                        value: selectedOptions,
                      });

                      if (selectedOptions !== null) {
                        setPagination((prev) => ({
                          ...prev,
                          filter: {
                            ...pagination.filter,
                          },
                        }));
                      }
                    }}
                  />
                </Form.Group>
              </Skeleton>

              <Form.Control.Feedback type="invalid">
                {errors?.vehicles?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mt-5">
            <Stack
              className="d-flex justify-content-end"
              direction="horizontal"
              gap={3}
            >
              <Button
                variant={"primary"}
                disabled={values.isLoading}
                type="submit"
              >
                {t("button.save")}
              </Button>
              <Button variant="secondary" onClick={handleCancel}>
                {t("button.cancel")}
              </Button>
            </Stack>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AssociateVehicleModal;
